import { Organization, TypeLegalPerson, TypeTaxation } from 'api/organization'
import { Optional } from 'util/type/Optional'
import { makeJsonSchema, SimpleSchema } from 'validation/validate'
import { metaOrganization } from 'entity/Organization'
import { cleanMaskNum } from '../common/Mask/Organization'

export const STRING = '^[а-яА-ЯёЁ \\.,\\-\\d\\(\\)"/№]+$'
export const ADDRESS_REGEXP = STRING

export type Org = Optional<Required<Omit<Organization,
  'scanInn' |
  'scanRegistration' |
  'scanCode' |
  'scanCertificate' |
  'scanTaxReturnOrFeeDeclaration' |
  'manningTable' |
  'calcPersonalIncomeTax' |
  'accountingBalance' |
  'registrationAddress' |
  'actualAddress' |
  'scanInsuredPersons' | 'scanReportEFS' |
  'scanRegistrationAddress' |
  'scanRequisites' | 'scanQuestionnaire' |
  'scanConsentTaxpayer' | 'scanReceiptConsentTaxpayer' |
  'scanBankCard' | 'scanRegulations' | 'scanAdministrationProtocol' |
  'phones' | 'emails' | 'participants' | 'carrierKisStatus' | 'kisQueuedId' | 'kisRequestHistory' |
  'legalAddressDetails' | 'mailingAddressDetails'
>>,
  'legalEqRegisteredAddress' |
  'mailingEqRegisteredAddress' |
  'actualEqLegalAddress' |
  'hasAccounting' | 'scanInformationLetter' | 'scanPatent' |
  'hasLicense' | 'scanLicense' | 'scanFinancialResults' |
  'hasPowerOfAttorney' | 'scanPowerOfAttorney' | 'okved'
>

export type OrgLtd = Omit<Org,
  'ogrnip' |
  'okpo'
>

export type OrgSt = Omit<Org,
  'okved' |
  'okveds' |
  'ogrn' |
  'kpp' |
  'organizationHeadName' |
  'appointmenDocumentTitle' | 'position' |
  'legalAddress' | 'mailingAddress'
>

export type OrgStUploadForCreateProfile = Optional<Required<Pick<Organization,
  'scanInn' |
  'scanRegistration' |
  'scanCode' |
  'scanCertificate' |
  'scanTaxReturnOrFeeDeclaration' |
  'manningTable' |
  // 'calcPersonalIncomeTax' |
  'accountingBalance' | 'scanInsuredPersons' |
  'scanReportEFS' |
  'scanRegistrationAddress' | 'scanInformationLetter' | 'scanPatent' |
  'scanLicense' | 'scanFinancialResults' |
  'scanRequisites' | 'scanQuestionnaire' |
  'scanConsentTaxpayer' | 'scanReceiptConsentTaxpayer' | 'scanPowerOfAttorney'
>>,
  'scanTaxReturnOrFeeDeclaration' |
  'manningTable' |
  // 'calcPersonalIncomeTax' |
  'accountingBalance' | 'scanInsuredPersons' |
  'scanReportEFS' |
  'scanRegistrationAddress' | 'scanInformationLetter' | 'scanPatent' |
  'scanLicense' | 'scanFinancialResults' |
  'scanRequisites' | 'scanQuestionnaire' |
  'scanConsentTaxpayer' | 'scanReceiptConsentTaxpayer' | 'scanPowerOfAttorney'
>

export type OrgLtdUploadForCreateProfile = Optional<Required<Pick<Organization,
  'scanInn' |
  'scanRegistration' |
  'scanCode' |
  'scanCertificate' |
  'scanTaxReturnOrFeeDeclaration' |
  'manningTable' |
  // 'calcPersonalIncomeTax' |
  'scanRequisites' | 'scanQuestionnaire' |
  'accountingBalance' | 'scanInformationLetter' | 'scanPatent' |
  'scanLicense' | 'scanFinancialResults' |
  'scanInsuredPersons' | 'scanReportEFS' |
  'scanConsentTaxpayer' | 'scanRegistrationAddress' | 'scanReceiptConsentTaxpayer' |
  'scanBankCard' | 'scanRegulations' | 'scanAdministrationProtocol'
>>,
  'scanTaxReturnOrFeeDeclaration' |
  'manningTable' |
  // 'calcPersonalIncomeTax' |
  'scanRequisites' | 'scanQuestionnaire' |
  'accountingBalance' | 'scanInformationLetter' | 'scanPatent' |
  'scanLicense' | 'scanFinancialResults' |
  'scanInsuredPersons' | 'scanReportEFS' |
  'scanConsentTaxpayer' | 'scanRegistrationAddress' | 'scanReceiptConsentTaxpayer' |
  'scanBankCard' | 'scanRegulations' | 'scanAdministrationProtocol'>

const simpleSchemaLtd: SimpleSchema<OrgLtd> = {
  type: 'object',
  properties: {
    typeLegalPerson: { type: 'string', enum: [TypeLegalPerson.organization] },
    typeTaxation: { type: 'string', enum: Object.values(TypeTaxation) },
    inn: { type: 'string', pattern: '^\\d{10}$', errorMessage: 'Некорректно заполнен ИНН' },
    okved: { type: 'string', optional: true, pattern: '^\\d{2,6}$', errorMessage: 'Поле обязательно к заполнению' },
    okveds: { type: 'array', items: { type: 'string', errorMessage: 'Поле обязательно к заполнению' } },
    ogrn: { type: 'string', pattern: '^\\d{13}$', errorMessage: 'Некорректно заполнен ОГРН' },
    correspondentAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер корреспондентского счета' },
    kpp: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен КПП' },
    checkingAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер рс' },
    bik: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен БИК' },
    shortName: { type: 'string', pattern: STRING, minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    fullName: { type: 'string', pattern: STRING, minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    bankName: { type: 'string', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    organizationHeadName: { type: 'string', pattern: '^[а-яА-ЯёЁ .]+$', minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    appointmenDocumentTitle: { type: 'string', pattern: STRING, errorMessage: 'Некорректно заполнено поле' },
    legalAddress: { type: 'string', pattern: ADDRESS_REGEXP, minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    mailingAddress: { type: 'string', pattern: ADDRESS_REGEXP, minLength: 10, errorMessage: 'Некорректно заполнено поле' },
    position: { type: 'string', pattern: STRING, minLength: 3, errorMessage: 'Некорректно заполнено поле' },
    legalEqRegisteredAddress: { type: 'boolean', optional: true },
    mailingEqRegisteredAddress: { type: 'boolean', optional: true },
    actualEqLegalAddress: { type: 'boolean', optional: true },
    hasAccounting: { type: 'boolean', optional: true },
    hasLicense: { type: 'boolean', optional: true },
    scanInformationLetter: { type: 'string', optional: true },
    scanPatent: { type: 'string', optional: true },
    scanLicense: { type: 'string', optional: true },
    scanFinancialResults: { type: 'string', optional: true },
    hasPowerOfAttorney: { type: 'boolean', optional: true },
    scanPowerOfAttorney: { type: 'string', optional: true }
  }
}

const simpleSchemaSt: SimpleSchema<OrgSt> = {
  type: 'object',
  properties: {
    typeLegalPerson: { type: 'string', enum: [TypeLegalPerson.st] },
    typeTaxation: { type: 'string', enum: Object.values(TypeTaxation) },
    inn: { type: 'string', pattern: '^\\d{12}$', errorMessage: 'Некорректно заполнен ИНН' },
    ogrnip: { type: 'string', pattern: '^\\d{15}$', errorMessage: 'Некорректно заполнено ОГРНИП' },
    okpo: { type: 'string', pattern: '^\\d{10}$', errorMessage: 'Некорректно заполнен ОКПО' },
    correspondentAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер корреспондентского счета' },
    checkingAccountNumber: { type: 'string', pattern: '^\\d{20}$', errorMessage: 'Некорректно заполнен номер рс' },
    bik: { type: 'string', pattern: '^\\d{9}$', errorMessage: 'Некорректно заполнен БИК' },
    shortName: { type: 'string', pattern: STRING, minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    fullName: { type: 'string', pattern: STRING, minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    bankName: { type: 'string', minLength: 5, errorMessage: 'Некорректно заполнено поле' },
    legalEqRegisteredAddress: { type: 'boolean', optional: true },
    mailingEqRegisteredAddress: { type: 'boolean', optional: true },
    actualEqLegalAddress: { type: 'boolean', optional: true },
    hasAccounting: { type: 'boolean', optional: true },
    hasLicense: { type: 'boolean', optional: true },
    scanInformationLetter: { type: 'string', optional: true },
    scanPatent: { type: 'string', optional: true },
    scanLicense: { type: 'string', optional: true },
    scanFinancialResults: { type: 'string', optional: true },
    hasPowerOfAttorney: { type: 'boolean', optional: true },
    scanPowerOfAttorney: { type: 'string', optional: true }
  }
}

const simpleSchemaLtdUp: SimpleSchema<OrgLtdUploadForCreateProfile> = {
  type: 'object',
  properties: {
    manningTable: { type: 'string', optional: true },
    // calcPersonalIncomeTax: { type: 'string' },
    scanInn: { type: 'string' },
    scanRegistration: { type: 'string' },
    scanCode: { type: 'string' },
    scanCertificate: { type: 'string' },
    scanTaxReturnOrFeeDeclaration: { type: 'string', optional: true },
    scanQuestionnaire: { type: 'string', optional: true },
    scanConsentTaxpayer: { type: 'string', optional: true },
    scanReceiptConsentTaxpayer: { type: 'string', optional: true },
    scanRegistrationAddress: { type: 'string', optional: true },
    scanBankCard: { type: 'string', optional: true },
    scanRegulations: { type: 'string', optional: true },
    scanAdministrationProtocol: { type: 'string', optional: true },
    scanRequisites: { type: 'string', optional: true },
    accountingBalance: { type: 'string', optional: true },
    scanInformationLetter: { type: 'string', optional: true },
    scanPatent: { type: 'string', optional: true },
    scanLicense: { type: 'string', optional: true },
    scanFinancialResults: { type: 'string', optional: true },
    scanInsuredPersons: { type: 'string', optional: true },
    scanReportEFS: { type: 'string', optional: true }
  }
}

const simpleSchemaStUp: SimpleSchema<OrgStUploadForCreateProfile> = {
  type: 'object',
  properties: {
    // calcPersonalIncomeTax: { type: 'string' },
    scanInn: { type: 'string' },
    scanRegistration: { type: 'string' },
    scanCode: { type: 'string' },
    scanCertificate: { type: 'string' },
    scanTaxReturnOrFeeDeclaration: { type: 'string', optional: true },
    scanQuestionnaire: { type: 'string', optional: true },
    scanInsuredPersons: { type: 'string', optional: true },
    scanConsentTaxpayer: { type: 'string', optional: true },
    scanReceiptConsentTaxpayer: { type: 'string', optional: true },
    scanRegistrationAddress: { type: 'string', optional: true },
    accountingBalance: { type: 'string', optional: true },
    scanReportEFS: { type: 'string', optional: true },
    scanInformationLetter: { type: 'string', optional: true },
    scanPatent: { type: 'string', optional: true },
    scanLicense: { type: 'string', optional: true },
    scanFinancialResults: { type: 'string', optional: true },
    scanRequisites: { type: 'string', optional: true },
    manningTable: { type: 'string', optional: true },
    scanPowerOfAttorney: { type: 'string', optional: true }
  }
}

export const schemaLtd = makeJsonSchema<OrgLtd>(simpleSchemaLtd)
export const schemaSt = makeJsonSchema<OrgSt>(simpleSchemaSt)
export const schemaUploadForCreateStProfile = makeJsonSchema<OrgStUploadForCreateProfile>(simpleSchemaStUp)
export const schemaUploadForCreateLtdProfile = makeJsonSchema<OrgLtdUploadForCreateProfile>(simpleSchemaLtdUp)

export function clear ({
  ogrnip, okpo, ogrn, okved, kpp, okveds, ...data
}: Organization): Organization {
  const cleanMask = (key: keyof Organization, value?: string): string | undefined =>
    value !== undefined ? metaOrganization().cleanMask(key, value) : value

  const result = {
    ...data,
    correspondentAccountNumber: cleanMask('correspondentAccountNumber', data.correspondentAccountNumber),
    checkingAccountNumber: cleanMask('checkingAccountNumber', data.checkingAccountNumber),
    bik: cleanMask('bik', data.bik),
    inn: cleanMask('inn', data.inn)
  }

  if (data.typeLegalPerson === TypeLegalPerson.organization) {
    return {
      ...result,
      ogrn: cleanMask('ogrn', ogrn),
      kpp: cleanMask('kpp', kpp),
      okveds: okveds?.map((v:string) => cleanMaskNum(v)),
      okved: cleanMask('okved', okved)
    }
  } else {
    return {
      ...result,
      ogrnip: cleanMask('ogrnip', ogrnip),
      okpo: cleanMask('okpo', okpo)
    }
  }
}
