import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import NavigatePanel from 'ui/NavigatePanel'
import {
  BidForUpdate,
  bidAllGet,
  bidAllUpdate,
  bidAllCreate,
  bidAllClone,
  bidSlotList,
  bidSlotUpsert,
  BidMethodType,
  BidMethod,
  bidMethodList as apiMethodList,
  BidCargo,
  bidCargoList as apiCargoList,
  BidStatus,
  Bid,
  bidAllClose,
  BidForCreate
} from 'api/bid'
import { Address, AddressPointType } from 'api/address'
import { vehicleTypeList as apiVehicleTypeList } from 'api/vehicle'
import './styles.sass'
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Id, isId } from 'api/Id'
import InfoCard from 'ui/InfoCard'
import { Button, Stack, Typography, Box } from '@mui/material'
import { SelectOptions } from 'common/SelectOptionsInterface'
import Select from 'ui/Select'
import TextField from 'ui/TextField'
import { schemaBidManaged, schemaBidRelay } from 'validation/Bid'
import schemaSlot from 'validation/slot'
import useValidate from 'validation/validate'
import { useAuthContext } from 'AuthContext'
import { useMainRoutes } from 'routes'
import { routeFind as apiRouteFind, RouteFindResult } from 'api/route'
import SelectAddress from 'ui/SelectAddress'
import InfoField from 'ui/InfoField'
import Map from 'ui/Osm'
import toFixedNumber from 'util/toFixedNumber'
import { clear, now, today } from 'util/date'
import {
  customerDispatcherList as apiCustomerList,
  Customer
} from 'api/customer'
import FreeSpaceEdit, { UpdateParamsSlot } from './FreeSpaceEdit'
import { Delete, NewReleases, Report, Save } from '@mui/icons-material'
import TruckRunsList from './TruckRunsList'
import formateParties from 'util/formateParties'
import DeleteModal from './DeleteModal'
import formatCargo from 'util/formatCargo'
import InfoCell from 'ui/InfoCell'
import InfoCellValue from 'ui/InfoCell/InfoCellValue'
import formatWeight from 'util/formatWeight'
import { toKopeck, toRuble, costTonKm as calcCostTonKm } from 'util/monetary'
import valueMethod, { STUB, valueToArray } from 'util/valueMethod'
import { toKilometers, toMeters } from 'util/distance'
import { KILOGRAM_IN_TON, toKilogram, toTon } from 'util/weight'
import { SEARCH_PARAMS as SEARCH_PARAMS_ROUTE } from 'pages/Registry/Routes/Route'
import { checkMaxLoad, checkScaleLength } from 'util/checkWeighingValue'
import { DAY } from 'constants/Time'
import SelectPointType from 'ui/SelectAddress/SelectPointType'
import SelectOrganization from 'ui/SelectOrganization'
import formatWorkSchedule from 'util/formatWorkSchedule'
import { heightRestrictionCheck } from 'util/checkHeightRestrictionValue'
import { skipDiadoc } from 'ui/ActWizard'
import { ProfileAll, profileAllList, ProfileContractStatus } from 'api/profile'
import { UserRole } from 'api/user'
import { UserStatus } from 'api/UserStatus'
import AntSwitch from 'ui/AntSwitch'
import DatePicker from 'ui/DatePicker'
import onlyFields from 'util/onlyFields'
import BlockedManualFillModal from './BlockedManualFillModal'
import UnblockManualFillModal from './UnblockManualFillModal'
import { checkUnderweightValue } from 'util/checkUnderweightValue'
import Tooltip from 'ui/Tooltip'
import ExtensionBidModal from './ExtensionBidModal'

const DEFAULT_AVERAGE_WEIGHT_KG = 25000

export type UpdateParamsBid = Omit<BidForUpdate, 'id'>

const unsignedNum = (d?: number) => d !== undefined && d > 0 ? d : 0

export enum Tabs {
  basicInfo = 'basic_info',
  freeSpace = 'free_space',
  truckRuns = 'truck_runs',
  truckRunsRefused = 'truck_runs_refused',
  truckRunsRedirected = 'truck_runs_redirected'
}

const TABS_ITEMS = [
  { label: 'Основная информация', value: Tabs.basicInfo }
]

const TAB_FREE_SPACE = { label: 'Машино-места', value: Tabs.freeSpace }
const TAB_TRUCK_RUNS = { label: 'Рейсы', value: Tabs.truckRuns }
const TAB_TRUCK_RUNS_REFUSED = { label: 'Отозванные рейсы', value: Tabs.truckRunsRefused }
const TAB_TRUCK_RUNS_REDIRECTED = { label: 'Переадресованные рейсы', value: Tabs.truckRunsRedirected }

type Statistics = Bid['statistics']
type InfoPanelData = {
  // cargo?: BidCargo
  // totalWeight?: number
  // distance?: number
  allSeats?: number
  allSeatsWeight?: number
  allFreeSeats?: number
  allFreeSeatsWeight?: number
  neededCountSeat?: number
  neededWeight?: number
}

export enum SEARCH_PARAMS {
  tab = 'tab',
  truckRunId = 'tr_id'
}

export default function DispatcherBid () {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()
  const { bidId } = useParams()
  const { check: bidCheckManaged, errors: bidErrorsManaged } = useValidate(schemaBidManaged)
  const { check: bidCheckRelay, errors: bidErrorsRelay } = useValidate(schemaBidRelay)
  const { check: slotCheck, errors: slotErrors } = useValidate(schemaSlot)
  const [vehicleTypeList, setVehicleTypeList] = useState<SelectOptions[]>([])
  const [methodList, setMethodList] = useState<BidMethod[]>([])
  const [cargoList, setCargoList] = useState<BidCargo[]>([])
  const [slotList, setSlotList] = useState<UpdateParamsSlot[]>([])
  const [data, setData] = useState<UpdateParamsBid>({ typeVehicleSlugs: [] })
  const [isNew, setIsNew] = useState<boolean>(true)
  const [isRelay, setIsRelay] = useState<boolean>(false)
  const [loadingAddress, setLoadingAddress] = useState<Address>()
  const [unloadingAddress, setUnloadingAddress] = useState<Address>()
  const [costTonKm, setCostTonKm] = useState<number>()
  const [routeList, setRouteList] = useState<RouteFindResult[]>([])
  const [routeDistance, setRouteDistance] = useState<number>()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [bidNum, setBidNum] = useState<string>('')
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.basicInfo)
  const [tabs, setTabs] = useState(TABS_ITEMS)
  const [statistics, setStatistics] = useState<Statistics>()
  const [deleteId, setDeleteId] = useState<Id>()
  const [blockedId, setBlockedId] = useState<Id>()
  const [unblockedId, setUnblockedId] = useState<Id>()
  const [averageWeight, setAverageWeight] = useState<number>()
  const [found, setFound] = useState(false)
  const [infoPanelData, setInfoPanelData] = useState<InfoPanelData>({})
  const [maxNewSeats, setMaxNewSeats] = useState(0)
  const [disabledSave, setDisabledSave] = useState(false)
  const [loadingAddressType, setLoadingAddressType] = useState<AddressPointType>(AddressPointType.grainElevator)
  const [unloadingAddressType, setUnloadingAddressType] = useState<AddressPointType>(AddressPointType.grainElevator)
  const [viewTruckRunId, setViewTruckRunId] = useState<Id>()
  const [carrierList, setCarrierList] = useState<ProfileAll[]>([])
  const [carrier, setCarrier] = useState<ProfileAll>()
  const [blockedManualFill, setBlockedManualFill] = useState<boolean>()
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [bid, setBid] = useState<Bid>()
  const [extensionBid, setExtensionBid] = useState<BidForUpdate>()

  const navigate = useNavigate()
  const { links } = useMainRoutes()
  const [searchParams] = useSearchParams()

  const canDeleted = useMemo(() => {
    return !isNew && !statistics?.filledSeats
  }, [isNew, statistics])

  const addSlot = () => {
    const ts = today()
    const rowId = now()
    const lastDay = Math.max(...slotList.map(item => item.shipmentTs ?? 0))
    const shipmentTs = ts > lastDay ? ts : lastDay + DAY

    setSlotList(prev => [...prev, { rowId: `${rowId}`, shipmentTs }])
  }

  const init = useCallback(async () => {
    setData({ typeVehicleSlugs: [] })
    apiVehicleTypeList().then(result => setVehicleTypeList(result.map(item => ({ value: item.slug, name: item.name }))))
    apiMethodList().then(setMethodList)
    apiCargoList({ filters: { hidden: false, onlyGroups: true } }).then(setCargoList)
    apiCustomerList({ filters: { onlyComplete: !skipDiadoc() } }).then(setCustomers)

    const result = isId(bidId) ? await bidAllGet(bidId) : null

    if (result !== null) {
      const { statistics, cargo, totalWeight, distance, consignee, consignor, blockedManualFill: bmf } = result

      setBid(result)
      setData({
        loadingAddressId: result.loadingAddress.id,
        unloadingAddressId: result.unloadingAddress.id,
        typeVehicleSlugs: result.typeVehicle.map(item => item.slug),
        cargo: cargo.slug,
        totalWeight,
        distance,
        costTon: result.costTon,
        costCargo: result.costCargo,
        loadingMethod: result.loadingMethod,
        unloadingMethod: result.unloadingMethod,
        customerId: result.customer.id,
        consigneeId: consignee.id,
        consignorId: consignor.id,
        plusTen: result.plusTen,
        loadingWeighing: result.loadingWeighing,
        unloadingWeighing: result.unloadingWeighing,
        noСlaim: result.noСlaim ?? false,
        underweight: result.underweight,
        carrierId: result.carrier?.id,
        autoFill: result.autoFill,
        beginTs: result.beginTs,
        endTs: result.endTs
      })
      setCarrier(result.status === BidStatus.new ? undefined : result.carrier)
      setLoadingAddress(result.loadingAddress)
      setUnloadingAddress(result.unloadingAddress)
      setLoadingAddressType(result.loadingAddress.pointType)
      setUnloadingAddressType(result.unloadingAddress.pointType)
      setIsNew(false)
      setDisabled(result.status !== BidStatus.new)
      setBidNum(result.num)
      setStatistics(statistics)
      setAverageWeight(result.cargo.averageWeight)
      setBlockedManualFill(bmf)

      // setInfoPanelData({
      //   cargo,
      //   totalWeight: totalWeight / UNIT_WEIGHT_KG,
      //   distance
      // })
    }

    const carrierList = await profileAllList({ role: UserRole.carrier, status: [UserStatus.active] })
    setCarrierList(carrierList)

    if (bidId === 'relay' || (result && result.carrier)) {
      setIsRelay(true)
    }

    setFound(true)
  }, [bidId])

  useEffect(() => { init() }, [init])

  const saveBidData = useCallback(async () => {
    if (isRelay && !data.autoFill) {
      data.autoFill = false
    }

    const cleanData = isRelay
      ? data
      : onlyFields(data, 'loadingAddressId',
        'unloadingAddressId', 'typeVehicleSlugs', 'cargo',
        'totalWeight', 'distance', 'costTon', 'costCargo', 'loadingMethod',
        'unloadingMethod', 'customerId', 'consigneeId', 'consignorId',
        'plusTen', 'loadingWeighing', 'unloadingWeighing', 'noСlaim',
        'underweight')

    if (isRelay ? !bidCheckRelay(cleanData) : !bidCheckManaged(cleanData)) {
      handleResponseFailure('Заполните все поля')
      // console.info(bidErrors)
      return null
    }

    if (isId(bidId)) {
      const result = await bidAllUpdate({ id: bidId, ...cleanData })

      if (result) {
        handleResponseSuccess('Данные заявки изменены')
        init()
      }

      return bidId
    } else {
      const id = await bidAllCreate(cleanData as BidForCreate)

      if (id !== null) {
        handleResponseSuccess('Заявка создана')
        navigate(`${links.DISPATCHER_BID_PAGE}/${id}`)
        return id
      }

      return null
    }
  }, [isRelay, bidCheckManaged, bidCheckRelay, data, bidId, init, handleResponseSuccess, handleResponseFailure, links.DISPATCHER_BID_PAGE, navigate])

  useEffect(() => {
    if (statistics !== undefined) {
      const { filledSeats, seats, truckRuns: { totalSeats, totalWeight, archiveRefuse: { count: refusedCount }, totalRedirected } } = statistics
      setTabs(prev => {
        let result = prev

        if (!isRelay && prev.find(item => item.value === TAB_FREE_SPACE.value) === undefined) {
          result = [...result, TAB_FREE_SPACE]
        }

        if (isRelay) {
          result = result.filter(item => item.value !== TAB_FREE_SPACE.value)
        }

        if ((isRelay || filledSeats > 0) && prev.find(item => item.value === TAB_TRUCK_RUNS.value) === undefined) {
          result = [...result, TAB_TRUCK_RUNS]
        }

        if ((!isRelay && filledSeats === 0)) {
          result = result.filter(item => item.value !== TAB_TRUCK_RUNS.value)
        }

        if (refusedCount > 0 && prev.find(item => item.value === TAB_TRUCK_RUNS_REFUSED.value) === undefined) {
          result = [...result, TAB_TRUCK_RUNS_REFUSED]
        }

        if (refusedCount === 0) {
          result = result.filter(item => item.value !== TAB_TRUCK_RUNS_REFUSED.value)
        }

        if (totalRedirected > 0 && prev.find(item => item.value === TAB_TRUCK_RUNS_REDIRECTED.value) === undefined) {
          result = [...result, TAB_TRUCK_RUNS_REDIRECTED]
        }

        if (totalRedirected === 0) {
          result = result.filter(item => item.value !== TAB_TRUCK_RUNS_REDIRECTED.value)
        }

        return result
      })

      if (averageWeight === undefined) {
        return
      }

      const allFreeSeats = seats - filledSeats

      setInfoPanelData(prev => ({
        ...prev,
        allFreeSeats,
        allFreeSeatsWeight: toTon(allFreeSeats * averageWeight),
        allSeats: totalSeats,
        allSeatsWeight: toTon(totalWeight)
      }))
    }
  }, [statistics, averageWeight, isRelay])

  useEffect(() => {
    if (averageWeight === undefined) {
      return
    }

    if (data.totalWeight === undefined) {
      return
    }

    const totalWeight = data.totalWeight

    const { allFreeSeatsWeight, allFreeSeats } = slotList.reduce((prev, cur) => {
      const { seats = 0 } = cur
      const freeSeats = seats
      const freeSeatsWeight = freeSeats * averageWeight
      return {
        allFreeSeats: prev.allFreeSeats + freeSeats,
        allFreeSeatsWeight: prev.allFreeSeatsWeight + freeSeatsWeight
      }
    }, {
      allFreeSeats: 0,
      allFreeSeatsWeight: 0
    })

    const neededWeight = totalWeight - allFreeSeatsWeight
    const neededCountSeat = neededWeight / averageWeight
    const differenceOneTon = neededWeight - averageWeight - KILOGRAM_IN_TON >= 0

    setInfoPanelData(prev => ({
      ...prev,
      allFreeSeatsWeight: toTon(allFreeSeatsWeight),
      allFreeSeats,
      neededCountSeat: differenceOneTon ? Math.ceil(neededCountSeat) : Math.round(neededCountSeat),
      neededWeight: toTon(neededWeight)
    }))
  }, [slotList, averageWeight, data.totalWeight])

  useEffect(() => {
    if (statistics === undefined) {
      return
    }

    if (averageWeight === undefined) {
      return
    }

    if (data.totalWeight === undefined) {
      return
    }

    const { truckRuns: { totalFacticalWeight } } = statistics

    const left = data.totalWeight - totalFacticalWeight

    const max = Math.ceil(left / averageWeight)

    setMaxNewSeats(max)
  }, [statistics, averageWeight, data.totalWeight])

  const initSlots = useCallback(() => {
    if (isId(bidId)) {
      bidSlotList(bidId).then(result => {
        if (result.length === 0) {
          const ts = today()
          const rowId = now()
          setSlotList(result.map((item) => ({ ...item, rowId: `${rowId}`, shipmentTs: ts })))
          return
        }

        setSlotList(result.map((item) => ({ ...item, rowId: `${item.shipmentTs}` })))
      })
    }
  }, [bidId])

  useEffect(() => { initSlots() }, [initSlots])

  const hasFreeSlots: boolean = useMemo(() => {
    return slotList.reduce((prev, { filledSeats = 0, seats = 0 }) => prev + (seats - filledSeats), 0) > 0
  }, [slotList])

  const changeTab = useCallback(async (value: string) => {
    const nextTab = value as Tabs
    const cleanURL = () => navigate(`${links.DISPATCHER_BID_PAGE}/${bidId}`)

    if (isNew && nextTab === Tabs.freeSpace) {
      const result = await saveBidData()
      if (result === null) {
        return
      }

      navigate(`${links.DISPATCHER_BID_PAGE}/${result}?${SEARCH_PARAMS.tab}=${nextTab}`)
      initSlots()

      return
    }

    if (!isNew && nextTab === Tabs.freeSpace) {
      initSlots()
    }

    if (nextTab === Tabs.truckRuns) {
      if (!isRelay && !statistics?.filledSeats) {
        cleanURL()
        return
      }
    }

    if (nextTab === Tabs.basicInfo) {
      cleanURL()
    } else {
      navigate(`?${SEARCH_PARAMS.tab}=${nextTab}`)
    }

    setCurrentTab(nextTab)
    setViewTruckRunId(undefined)
  }, [isNew, navigate, links.DISPATCHER_BID_PAGE, bidId, saveBidData, initSlots, isRelay, statistics?.filledSeats])

  useEffect(() => {
    const tab = searchParams.get(SEARCH_PARAMS.tab)
    const truckRunId = searchParams.get(SEARCH_PARAMS.truckRunId)
    if (!isNew && tab !== null && currentTab !== (tab as Tabs)) {
      changeTab(tab)
    }

    if (isId(truckRunId)) {
      setViewTruckRunId(truckRunId)
    }
  }, [searchParams, changeTab, isNew, currentTab])

  useEffect(() => {
    if (data.loadingAddressId === undefined || data.unloadingAddressId === undefined) {
      return
    }

    apiRouteFind({ loadingAddressId: data.loadingAddressId, unloadingAddressId: data.unloadingAddressId }).then(result => {
      setRouteList(result)
      if (result.length > 0) {
        setRouteDistance(result[0].distance)
      }
    })
  }, [data.loadingAddressId, data.unloadingAddressId])

  useEffect(() => {
    setData(prev => ({
      ...prev,
      distance: routeDistance
    }))
  }, [routeDistance])

  useEffect(() => {
    if (data.costTon !== undefined && data.distance !== undefined) {
      setCostTonKm(data.distance > 0 ? calcCostTonKm(data.costTon, data.distance) : 0)
    }
  }, [data.costTon, data.distance])

  useEffect(() => {
    if (cargoList.length === 0) {
      return
    }

    if (data.cargo === undefined) {
      return
    }

    const { averageWeight = DEFAULT_AVERAGE_WEIGHT_KG, cargoType } = cargoList.find(item => item.slug === data.cargo) ?? {}

    setAverageWeight(averageWeight)

    if (cargoType) {
      const { underweight } = cargoType
      setData(prev => ({ ...prev, underweight }))
    }
  }, [data.cargo, cargoList])

  const saveSlotData = useCallback(async () => {
    const upsertData = {
      bidId,
      slots: slotList.map(({ shipmentTs, seats }) => ({ shipmentTs, seats }))
    }
    if (!slotCheck(upsertData)) {
      handleResponseFailure('Заполните все поля')
      return
    }

    if (await bidSlotUpsert(upsertData)) {
      init()
      initSlots()
      handleResponseSuccess('Машино-места изменены')
    }
  }, [slotCheck, slotList, bidId, handleResponseSuccess, handleResponseFailure, init, initSlots])

  const updateBidMainSelect = (key: keyof UpdateParamsBid) => {
    return (slug?: string) => {
      setData({ ...data, [key]: slug })
    }
  }

  const updateCost = (key: keyof UpdateParamsBid) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const num = Number(e.target.value)
      setData({ ...data, [key]: num ? toKopeck(num) : undefined })
    }
  }

  const updateDistance = (key: keyof UpdateParamsBid) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const num = Number(e.target.value)
      setData({ ...data, [key]: num ? toMeters(Number(num)) : undefined })
    }
  }

  const updateBidTon = (key: keyof UpdateParamsBid) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const num = Number(e.target.value)
      setData({ ...data, [key]: num ? toKilogram(num) : undefined })
    }
  }

  const updateLoadingAddress = (loadingAddressId: Id, address?: Address) => {
    setLoadingAddress(address)

    setData(prev => {
      const _set: UpdateParamsBid = { loadingAddressId }

      if (!address) {
        return { ...prev, ..._set }
      }

      const { weighingScaleLength, weighingScaleMaxLoad, loadingMethod } = address

      if (!prev.loadingWeighing) {
        _set.loadingWeighing = { weighingScaleLength, weighingScaleMaxLoad }
      }

      if (!prev.loadingMethod && loadingMethod) {
        _set.loadingMethod = loadingMethod
      }

      return { ...prev, ..._set }
    })
  }

  const updateUnloadingAddress = (unloadingAddressId: Id, address?: Address) => {
    setUnloadingAddress(address)

    setData(prev => {
      const _set: UpdateParamsBid = { unloadingAddressId }

      if (!address) {
        return { ...prev, ..._set }
      }

      const { weighingScaleLength, weighingScaleMaxLoad, unloadingMethod } = address

      if (!prev.unloadingWeighing) {
        _set.unloadingWeighing = { weighingScaleLength, weighingScaleMaxLoad }
      }

      if (!prev.unloadingMethod && unloadingMethod) {
        _set.unloadingMethod = unloadingMethod
      }

      return { ...prev, ..._set }
    })
  }

  const deleteSlot = (current: UpdateParamsSlot) => {
    setSlotList(prev => prev.filter(item => item.rowId !== current.rowId))
  }

  const updateSlotshipmentTs = (current: UpdateParamsSlot) => {
    return (ts: number) => {
      setSlotList(prev => prev.map(item => {
        if (item.rowId !== current.rowId) {
          return item
        }

        return { ...item, shipmentTs: ts }
      }))
    }
  }

  const updateSlotSeats = (current: UpdateParamsSlot) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (!value.match(/^\d*$/)) {
        return
      }

      const seats = Number(value)

      setSlotList(prev => prev.map(item => {
        if (item.rowId !== current.rowId) {
          return item
        }

        return { ...item, seats: seats + (item?.filledSeats ?? 0) }
      }))
    }
  }

  const udateParties = (key: 'consignorId' | 'consigneeId') => {
    return (id?: Id) => {
      if (id === undefined || isId(id)) {
        setData(prev => ({ ...prev, [key]: id }))
      }
    }
  }

  const updateWeighingScaleLength = (key: 'loadingWeighing' | 'unloadingWeighing') => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const num = Number(value)
      setData({
        ...data,
        [key]: { ...data[key], weighingScaleLength: num > 0 ? num : undefined }
      })
    }
  }

  const updateWeighingScaleMaxLoad = (key: 'loadingWeighing' | 'unloadingWeighing') => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const num = Number(value)
      setData({
        ...data,
        [key]: { ...data[key], weighingScaleMaxLoad: num ? toKilogram(num) : undefined }
      })
    }
  }

  const updateHeightRestriction = (key: 'loadingWeighing' | 'unloadingWeighing') => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const num = Number(value)
      setData({
        ...data,
        [key]: { ...data[key], heightRestriction: num || undefined }
      })
    }
  }

  const updateUnderweight = () => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (data) {
        const { target: { value } } = event
        setData({ ...data, underweight: value === '' ? undefined : toKilogram(Number(value)) })
      }
    }
  }

  const clone = async () => {
    if (isId(bidId)) {
      bidAllClone(bidId).then((id) => {
        if (isId(id)) {
          navigate(`${links.DISPATCHER_BID_PAGE}/${id}`)
          handleResponseSuccess('Заявка склонирована')
        } else {
          handleResponseFailure('Не удалось склонировать заявку')
        }
      })
    }
  }

  const close = async () => {
    if (isId(bidId)) {
      bidAllClose(bidId).then((success) => {
        if (success) {
          init()
          initSlots()
          handleResponseSuccess('Заявка закрыта заранее')
        } else {
          handleResponseFailure('Не удалось закрыть заявку')
        }
      })
    }
  }

  const handleExtensionBid = () => {
    if (!bid) {
      return
    }

    const { id, totalWeight, beginTs, endTs } = bid

    setExtensionBid({ id, totalWeight, beginTs, endTs })
  }

  const carrierListFiltred = useMemo(() => {
    return carrierList
      .filter(item => item.contracts?.find(({ customer, status }) => status === ProfileContractStatus.signed && customer.id === data.customerId))
      .map(({ id, phone, organization }) => ({ value: id, name: `${organization?.shortName} (${organization?.inn}) ${phone}`, phone }))
  }, [carrierList, data.customerId])

  const bidErrors = useMemo(() => isRelay ? bidErrorsRelay : bidErrorsManaged, [bidErrorsManaged, bidErrorsRelay, isRelay])

  const title = isNew ? undefined : `Перевозка ${bidNum ?? ''}`

  if (!found) {
    return <></>
  }

  return (<>
    <div className='dispatcherBid'>
      <NavigatePanel
        breadcrumbs={{
          items: [
            { title: 'Заявки', to: links.DISPATCHER_BIDS_PAGE },
            { title: title || 'Добавить заявку на перевозку' }
          ]
        }}
        title={title || 'Новая заявка на перевозку'}
        actions={ <Stack direction='row' spacing={2} justifyContent='end'>
          { currentTab !== Tabs.truckRuns
            ? <>
              { currentTab === Tabs.basicInfo && <>
                { isRelay && bid?.status === BidStatus.fulfilled && <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={handleExtensionBid}
                >
                  Продлить заявку
                </Button> }
                { !isNew && !data.autoFill && <Button
                  variant='outlined'
                  color='secondary'
                  size='small'
                  onClick={() => blockedManualFill ? setUnblockedId(bidId as Id) : setBlockedId(bidId as Id)}>
                { blockedManualFill ? 'Снять ограничение' : 'Ограничить создание рейсов'}
                <Report sx={{ width: '20px', height: '20px', ml: '10px' }} color={blockedManualFill ? 'warning' : 'secondary'} />
                </Button> }
                { canDeleted && <Button variant='outlined' size='small' color='error' onClick={() => setDeleteId(bidId as Id)} >
                Удалить <Delete sx={{ width: '15px', height: '15px', ml: '10px' }}/>
                </Button>}
                { !disabled && <Button variant='outlined' size='small' onClick={() => navigate(links.DISPATCHER_BIDS_PAGE)} >
                  Отменить
                </Button> }
                { isId(bidId) && <Button variant='contained' color="primary" size='small' onClick={clone} >
                Склонировать
                </Button> }
                { isId(bidId) && hasFreeSlots && <Button variant='contained' color="warning" size='small' onClick={close} >
                Закрыть заранее
                </Button> }
                { !disabled && <Button variant='contained' size='small' sx={{ background: '#6DCD45' }} onClick={() => saveBidData()} disabled={disabledSave} >
                Сохранить <Save sx={{ width: '15px', height: '15px', ml: '10px' }}/>
                </Button> }
                { disabled && <Button variant='outlined' size='small' onClick={() => navigate(links.DISPATCHER_BIDS_PAGE)} >
                Закрыть
                </Button> }
              </> }
              { currentTab === Tabs.freeSpace && <>
                <Button variant='outlined' size='small' onClick={() => changeTab(Tabs.basicInfo)} >
                Отменить
                </Button>
                <Button variant='contained' size='small' sx={{ background: '#6DCD45' }} onClick={() => saveSlotData()} disabled={disabledSave} >
                Сохранить <Save sx={{ width: '15px', height: '15px', ml: '10px' }}/>
                </Button>
              </> }
            </>
            : <>
            { isId(bidId) && <Button variant='contained' color="primary" size='small' onClick={() => setOpenCreateModal(true)} >
            Добавить рейс вручную
            </Button> }
          </>
        }
        </Stack>
        }
        info={<Stack direction='column' m='1em 1.5em' gap={1}>
          {blockedManualFill && <Box bgcolor='#FFA800' borderRadius='4px' padding={1} color='#FFFFFF'>
            Ограничено создание рейсов
          </Box>}
          {carrier && <Box bgcolor='#624CE0' borderRadius='4px' padding={1} color='#FFFFFF'>
            Заявка передана перевозчику <b>{carrier.organization?.shortName}</b>
          </Box>}
        </Stack>}
        tabs={ isRelay && isNew
          ? undefined
          : {
              items: tabs,
              value: currentTab,
              onChange: changeTab
            }
        }
      />
      { ![Tabs.basicInfo, Tabs.truckRunsRefused].includes(currentTab) && <Box sx={{ background: '#ffffff', borderBottom: '1px solid #E2E8F0' }}>
        <Stack direction='row' spacing={2} m='1em 1.5em' gap={1} alignItems='center'>
          {/* <InfoCell label='Груз' value={infoPanelData.cargo?.name} /> */}
          <InfoCell label='Общий вес перевозки' value={formatWeight(data.totalWeight)} />
          <InfoCell label='Осталось перевезти' value={formatWeight(statistics?.truckRuns.remainingWeight)} />
          {/* <InfoCell label='Расстояние' value={`${infoPanelData.distance} км`} /> */}
          {/* <InfoCell label='Всего машино-мест' value={<InfoCellValue value1={infoPanelData.allSeats} value2={`${infoPanelData.allSeatsWeight} тн`} />} /> */}
          { currentTab === Tabs.freeSpace && <>
            <InfoCell label='Добавлено машино-мест' value={<InfoCellValue value1={infoPanelData.allFreeSeats} value2={`${infoPanelData.allFreeSeatsWeight} тн`} />} />
            <InfoCell label='Осталось' value={<InfoCellValue value1={unsignedNum(infoPanelData.neededCountSeat)} value2={`${unsignedNum(infoPanelData.neededWeight)} тн`} />} />
            <InfoCell label='Фактически погружено' value={formatWeight(statistics?.truckRuns.totalFacticalWeight)} />
          </> }
          { currentTab === Tabs.truckRuns && <>
            <InfoCell label='Запланировано' value={<InfoCellValue value1={statistics?.truckRuns.confirmed.count} value2={formatWeight(statistics?.truckRuns.confirmed.weight)} />} />
            <InfoCell label='В пути' value={<InfoCellValue value1={statistics?.truckRuns.way.count} value2={formatWeight(statistics?.truckRuns.way.weight)} />} />
            <InfoCell label='Выгружено' value={<InfoCellValue value1={statistics?.truckRuns.arrived.count} value2={formatWeight(statistics?.truckRuns.arrived.weight)} />} />
            <InfoCell label='Завершено' value={<InfoCellValue value1={statistics?.truckRuns.completed.count} value2={formatWeight(statistics?.truckRuns.completed.weight)} />} />
            <InfoCell label='Архив' value={<InfoCellValue value1={statistics?.truckRuns.archived.count} value2={formatWeight(statistics?.truckRuns.archived.weight)} />} />
            <InfoCell label='Просрочено' value={<InfoCellValue value1={statistics?.truckRuns.outdated.count} value2={formatWeight(statistics?.truckRuns.outdated.weight)} />} />
            <InfoCell label='Завершено диспетчером' value={<InfoCellValue value1={statistics?.truckRuns.archiveProblem.count} value2={formatWeight(statistics?.truckRuns.archiveProblem.weight)} />} />
          </> }
          { !!statistics?.truckRuns.createdByEmployee && <Stack direction='row' gap={1}>
            <Tooltip
              title={<Stack>
                <Typography fontSize='14px' fontWeight={600} >В перевозке были добавлены рейсы вручную</Typography>
              </Stack>}
              arrow
              placement='right'
              view
            >
              <NewReleases sx={{ color: '#FFA800', display: 'block' }} />
            </Tooltip>
          </Stack>}
        </Stack>
      </Box> }
      <div className='dispatcherBid_body'>
        { currentTab === Tabs.basicInfo && <div className='dispatcherBid_body_basicInfo'>
          <div className='dispatcherBid_body_basicInfo_addressCard'>
            <div className='dispatcherBid_body_basicInfo_addressCard_fields'>
              <Typography fontSize='18px' fontWeight={650} mb='1em'>Пункт погрузки</Typography>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <SelectPointType
                  width='33%'
                  value={loadingAddressType}
                  onChange={setLoadingAddressType}
                  disabled={disabled}
                />
                <Box width='67%' />
              </Stack>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <SelectAddress
                  name='loadingAddressId'
                  label='Название пункта'
                  placeholder='Выберите пункт погрузки'
                  onChange={updateLoadingAddress}
                  value={data.loadingAddressId}
                  width='50%'
                  disabled={disabled}
                  errors={bidErrors}
                  itemName
                  excludeIds={valueToArray(data.unloadingAddressId)}
                  pointType={loadingAddressType}
                  partOfParent={loadingAddress?.isPart}
                />
                <Box width='16%' />
                <InfoField
                  width='33%'
                  label='Режим работы'
                  placeholder='C 08:00 до 17:00'
                  value={formatWorkSchedule(loadingAddress?.schedule)}
                />
              </Stack>
              {/* <Stack direction='row' spacing={2}>
                <InfoField
                  width='33%'
                  label='Населенный пункт'
                  placeholder='Выберите пункт погрузки'
                  value={loadingAddress?.city}
                />
                <InfoField
                  width='33%'
                  label='Район'
                  placeholder='Выберите пункт погрузки'
                  value={loadingAddress?.district}
                />
                <InfoField
                  width='33%'
                  label='Область'
                  placeholder='Выберите пункт погрузки'
                  value={loadingAddress?.region}
                />
              </Stack> */}
              <Stack direction='row' spacing={2} >
                {/* <InfoField
                  width='33%'
                  label='Улица'
                  placeholder='Выберите пункт погрузки'
                  value={loadingAddress?.street}
                />
                <InfoField
                  width='33%'
                  label='Дом'
                  placeholder='Выберите пункт погрузки'
                  value={loadingAddress?.building}
                /> */}
                <InfoField
                  width='67%'
                  label='Адрес'
                  placeholder=''
                  value={loadingAddress?.addressOrig}
                />
                <InfoField
                  width='33%'
                  label='Комментарий'
                  placeholder=''
                  value={loadingAddress?.comment}
                />
              </Stack>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <Select
                  name='loadingMethod'
                  label='Способ погрузки'
                  placeholder='Выберите способ погрузки'
                  options={methodList.filter(({ type }) => type === BidMethodType.loading).map(({ slug, name }) => ({ value: slug, name }))}
                  value={data.loadingMethod}
                  onChange={updateBidMainSelect('loadingMethod')}
                  disabled={disabled}
                  errors={bidErrors}
                  width='33%'
                />
                <TextField
                  name='loadingWeighing/weighingScaleLength'
                  width='33%'
                  label='Длина весов'
                  placeholder='Введите значение'
                  value={data.loadingWeighing?.weighingScaleLength}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={1}
                  onChange={updateWeighingScaleLength('loadingWeighing')}
                  errorMessage={checkScaleLength(data.loadingWeighing?.weighingScaleLength)}
                />
                <TextField
                  name='loadingWeighing/weighingScaleMaxLoad'
                  width='33%'
                  label='Грузоподъемность весов'
                  placeholder='Введите значение'
                  value={valueMethod(data.loadingWeighing?.weighingScaleMaxLoad)(toTon)}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={2}
                  onChange={updateWeighingScaleMaxLoad('loadingWeighing')}
                  errorMessage={checkMaxLoad(data.loadingWeighing?.weighingScaleMaxLoad)}
                />
                <TextField
                  name='loadingWeighing/heightRestriction'
                  width='33%'
                  label='Высота ТС, м'
                  placeholder='Введите значение'
                  value={valueMethod(data.loadingWeighing?.heightRestriction)(STUB)}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={1}
                  onChange={updateHeightRestriction('loadingWeighing')}
                  errorMessage={heightRestrictionCheck(data.loadingWeighing?.heightRestriction)}
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                <SelectOrganization
                  name='consignorId'
                  label='Грузоотправитель'
                  placeholder='Выберете грузоотправителя'
                  value={data.consignorId}
                  onChange={udateParties('consignorId')}
                  errors={bidErrors}
                  disabled={disabled}
                  width='33%'
                />
                <Box width='33%'></Box>
              </Stack>
            </div>
            <div className='dispatcherBid_body_basicInfo_addressCard_map'>
              { loadingAddress?.coordinates === undefined
                ? <Typography>Укажите населенный пункт</Typography>
                : <Map markers={[{ point: loadingAddress.coordinates }]} staticMap />}
            </div>
          </div>
          <div className='dispatcherBid_body_basicInfo_addressCard'>
            <div className='dispatcherBid_body_basicInfo_addressCard_fields'>
              <Typography fontSize='18px' fontWeight={650} mb='1em'>Пункт разгрузки</Typography>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <SelectPointType
                  width='33%'
                  value={unloadingAddressType}
                  onChange={setUnloadingAddressType}
                  disabled={disabled}
                />
                <Box width='67%' />
              </Stack>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <SelectAddress
                  name='unloadingAddressId'
                  label='Название пункта'
                  placeholder='Выберите пункт разгрузки'
                  onChange={updateUnloadingAddress}
                  value={data.unloadingAddressId}
                  width='50%'
                  disabled={disabled}
                  errors={bidErrors}
                  itemName
                  excludeIds={valueToArray(data.loadingAddressId)}
                  pointType={unloadingAddressType}
                  partOfParent={unloadingAddress?.isPart}
                />
                <Box width='16%' />
                <InfoField
                  width='33%'
                  label='Режим работы'
                  placeholder='C 08:00 до 17:00'
                  value={formatWorkSchedule(unloadingAddress?.schedule)}
                />
              </Stack>
              {/* <Stack direction='row' spacing={2} >
                <InfoField
                  width='33%'
                  label='Населенный пункт'
                  placeholder='Выберите пункт разгрузки'
                  value={unloadingAddress?.city}
                />
                <InfoField
                  width='33%'
                  label='Район'
                  placeholder='Выберите пункт разгрузки'
                  value={unloadingAddress?.district}
                />
                <InfoField
                  width='33%'
                  label='Область'
                  placeholder='Выберите пункт разгрузки'
                  value={unloadingAddress?.region}
                />
              </Stack> */}
              <Stack direction='row' spacing={2} >
                {/* <InfoField
                  width='33%'
                  label='Улица'
                  placeholder='Выберите пункт разгрузки'
                  value={unloadingAddress?.street}
                />
                <InfoField
                  width='33%'
                  label='Дом'
                  placeholder='Выберите пункт разгрузки'
                  value={unloadingAddress?.building}
                /> */}
                <InfoField
                  width='67%'
                  label='Адрес'
                  placeholder=''
                  value={unloadingAddress?.addressOrig}
                />
                <InfoField
                  width='33%'
                  label='Комментарий'
                  placeholder=''
                  value={unloadingAddress?.comment}
                />
              </Stack>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <Select
                  name='unloadingMethod'
                  label='Способ разгрузки'
                  placeholder='Выберите способ разгрузки'
                  options={methodList.filter(({ type }) => type === BidMethodType.unloading).map(({ slug, name }) => ({ value: slug, name }))}
                  value={data.unloadingMethod}
                  onChange={updateBidMainSelect('unloadingMethod')}
                  disabled={disabled}
                  errors={bidErrors}
                  width='33%'
                />
                <TextField
                  name='unloadingWeighing/weighingScaleLength'
                  width='33%'
                  label='Длина весов'
                  placeholder='Введите значение'
                  value={data.unloadingWeighing?.weighingScaleLength}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={1}
                  onChange={updateWeighingScaleLength('unloadingWeighing')}
                  errorMessage={checkScaleLength(data.unloadingWeighing?.weighingScaleLength)}
                />
                <TextField
                  name='unloadingWeighing/weighingScaleMaxLoad'
                  width='33%'
                  label='Грузоподъемность весов'
                  placeholder='Введите значение'
                  value={valueMethod(data.unloadingWeighing?.weighingScaleMaxLoad)(toTon)}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={2}
                  onChange={updateWeighingScaleMaxLoad('unloadingWeighing')}
                  errorMessage={checkMaxLoad(data.unloadingWeighing?.weighingScaleMaxLoad)}
                />
                <TextField
                  name='unloadingWeighing/heightRestriction'
                  width='33%'
                  label='Высота ТС, м'
                  placeholder='Введите значение'
                  value={valueMethod(data.unloadingWeighing?.heightRestriction)(STUB)}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={1}
                  onChange={updateHeightRestriction('unloadingWeighing')}
                  errorMessage={heightRestrictionCheck(data.unloadingWeighing?.heightRestriction)}
                />
              </Stack>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <TextField
                  name='underweight'
                  label='Разрешенная недостача, тн'
                  disabled={disabled}
                  width='33%'
                  value={valueMethod(data.underweight)(toTon)}
                  onChange={updateUnderweight()}
                  errors={bidErrors}
                  errorMessage={checkUnderweightValue(data.underweight)}
                  typeNumber
                />
                <SelectOrganization
                  name='consigneeId'
                  label='Грузополучатель'
                  placeholder='Выберете грузополучателя'
                  value={data.consigneeId}
                  onChange={udateParties('consigneeId')}
                  disabled={disabled}
                  errors={bidErrors}
                  width='33%'
                />
                <Select
                  label='Маршрут'
                  placeholder='Выберите маршрут'
                  hint={!routeList.length
                    ? <NavLink
                      // to={`${links.REGISTRY_ROUTES_PAGE}/add`}
                      to={`${links.REGISTRY_ROUTES_PAGE}/add?${SEARCH_PARAMS_ROUTE.loadingAddressId}=${data.loadingAddressId}&${SEARCH_PARAMS_ROUTE.unloadingAddressId}=${data.unloadingAddressId}&${SEARCH_PARAMS_ROUTE.distance}=${data.distance}`}
                      target='_blank'
                      // Не работает если страница открывается в новой вкладке
                      // state={{
                      //   loadingAddressId: data.loadingAddressId,
                      //   unloadingAddressId: data.unloadingAddressId,
                      //   distance: data.distance
                      // }}
                      style={{ color: '#B2B2B2', textDecoration: 'none' }}>Добавить маршрут +</NavLink>
                    : undefined
                  }
                  options={routeList.map(({ distance, comment }) => ({ value: distance, name: comment || `${toKilometers(distance)}` }))}
                  value={routeList.length > 0 ? routeDistance : 0}
                  onChange={setRouteDistance}
                  disabled={disabled || !routeList.length}
                  width='33%'
                />
              </Stack>
            </div>
            <div className='dispatcherBid_body_basicInfo_addressCard_map'>
            { unloadingAddress?.coordinates === undefined
              ? <Typography>Укажите населенный пункт</Typography>
              : <Map markers={[{ point: unloadingAddress.coordinates }]} staticMap />}
            </div>
          </div>
          <InfoCard>
            <>
              <Typography fontSize='18px' fontWeight={650} mb='1em'>Основная информация</Typography>
              <Stack direction='row' justifyContent='space-between' spacing={2} >
                <Select
                  label='Тип ТС'
                  placeholder='Любой'
                  multiple={true}
                  options={vehicleTypeList}
                  value={data.typeVehicleSlugs}
                  onChange={(typeVehicleSlugs) => setData((data) => ({ ...data, typeVehicleSlugs }))}
                  disabled={disabled}
                  width='25%'
                  inputScroll={true}
                />
                <Select
                  name='cargo'
                  label='Груз'
                  placeholder='Выберите груз'
                  options={cargoList.map(item => ({ value: item.slug, name: formatCargo(item) }))}
                  value={data.cargo}
                  onChange={updateBidMainSelect('cargo')}
                  disabled={disabled}
                  errors={bidErrors}
                  width='25%'
                />
                <TextField
                  // TODO --- Необходимо переименовать cost Cargo во что-то более приемлемое (Стоимость груза за 1 тн)
                  name='costCargo'
                  label='Стоимость груза за 1 тн'
                  placeholder='​Укажите стоимость груза'
                  width='25%'
                  value={valueMethod(data.costCargo)(toRuble)}
                  onChange={updateCost('costCargo')}
                  endAdornment={<Typography className='infoIconText'>₽</Typography>}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={2}
                />
                <Select
                  name='customerId'
                  label='Заказчик перевозки'
                  placeholder='Выберите заказчика'
                  options={customers.map(item => ({ value: item.id, name: formateParties(item) }))}
                  value={data.customerId}
                  onChange={(customerId) => setData((data) => ({ ...data, customerId })) }
                  disabled={disabled || customers.length === 0}
                  errors={bidErrors}
                  width='25%'
                />
              </Stack>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <Select
                  name='plusTen'
                  label='Грузим +10%'
                  placeholder='Выберите значение'
                  options={[
                    { value: true, name: 'Да' },
                    { value: false, name: 'Нет' }
                  ]}
                  value={data.plusTen}
                  onChange={(plusTen) => setData((data) => ({ ...data, plusTen }))}
                  disabled={disabled}
                  errors={bidErrors}
                  width='20%'
                />
                <Select
                  name='noСlaim'
                  label='Претензия к перевозчику'
                  placeholder='Выберите значение'
                  options={[
                    { value: false, name: 'Да' },
                    { value: true, name: 'Нет' }
                  ]}
                  value={data.noСlaim}
                  onChange={(noСlaim) => setData((data) => ({ ...data, noСlaim }))}
                  disabled={disabled}
                  errors={bidErrors}
                  width='20%'
                />
                <TextField
                  name='totalWeight'
                  label='Общий вес перевозки, тн'
                  placeholder='​Укажите общий вес перевозки'
                  width='20%'
                  value={valueMethod(data.totalWeight)(toTon)}
                  onChange={updateBidTon('totalWeight')}
                  endAdornment={<Typography className='infoIconText'>тн.</Typography>}
                  typeNumber
                  disabled={disabled}
                  errors={bidErrors}
                />
                <TextField
                  label='Cтоимость перевозки 1 тн'
                  placeholder='​Укажите тариф на перевозку 1 тн'
                  name='costTon'
                  width='20%'
                  value={valueMethod(data.costTon)(toRuble)}
                  onChange={updateCost('costTon')}
                  endAdornment={<Typography className='infoIconText'>₽</Typography>}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                  precision={2}
                />
                <TextField
                  name='distance'
                  label='Расстояние'
                  placeholder='​Укажите расстояние'
                  width='20%'
                  value={valueMethod(data.distance)(toKilometers)}
                  onChange={updateDistance('distance')}
                  endAdornment={<Typography className='infoIconText'>км.</Typography>}
                  disabled={disabled}
                  errors={bidErrors}
                  typeNumber
                />
                <InfoField
                  label='Стоимость перевозки тн/км'
                  width='20%'
                  value={valueMethod(costTonKm)(v => toFixedNumber(toRuble(v), 2))}
                  endAdornment={<Typography className='infoIconText'>₽</Typography>}
                />
              </Stack>
            </>
          </InfoCard>
          { isRelay && <InfoCard style={{ marginTop: '1em' }}>
            <>
              <Typography fontSize='18px' fontWeight={650} mb='1em'>Перевозка</Typography>
              <Stack direction='row' justifyContent='space-between' spacing={2} >
                <Select
                  name='carrierId'
                  label='Перевозчик'
                  placeholder='Выберите перевозчика'
                  options={carrierListFiltred}
                  disableClearable={true}
                  value={data.carrierId}
                  onChange={(carrierId) => setData((data) => ({ ...data, carrierId }))}
                  disabled={disabled || !data.customerId || carrierListFiltred.length === 0}
                  errors={bidErrors}
                  width='100%'
                  errorMessage={
                    data.customerId === undefined
                      ? 'Выберете заказчика перевозки'
                      : carrierListFiltred.length === 0
                        ? 'Для выбранного заказчика, нет перевозчиков с подписанным заявлением'
                        : undefined
                  }
                />
                <DatePicker
                  name='beginTs'
                  width='20em'
                  label='Дата начала перевозок'
                  value={data.beginTs}
                  onChange={(beginTs) => setData((data) => ({
                    ...data,
                    beginTs,
                    endTs: data.endTs !== undefined && beginTs > data.endTs ? undefined : data.endTs
                  }))}
                  errors={bidErrors}
                  disabled={disabled}
                />
                <DatePicker
                  name='endTs'
                  width='20em'
                  label='Дата завершения перевозок'
                  value={data.endTs}
                  onChange={(endTs) => setData((data) => ({ ...data, endTs }))}
                  errors={bidErrors}
                  disabled={disabled}
                  shouldDisableDate={(day) => data.beginTs !== undefined && clear(day.getTime()) < data.beginTs}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <AntSwitch value={!data.autoFill} checked={!data.autoFill} onChange={(_event, checked) => setData((data) => ({ ...data, autoFill: !checked }))} disabled={disabled} />
                  <Typography sx={{ whiteSpace: 'nowrap' }}>Указывать запланированные рейсы</Typography>
                </Stack>
              </Stack>
            </>
          </InfoCard> }
        </div> }
        { currentTab === Tabs.freeSpace && <FreeSpaceEdit
          slotList={slotList}
          slotErrors={slotErrors}
          data={data}
          addSlot={addSlot}
          deleteSlot={deleteSlot}
          updateSlotSeats={updateSlotSeats}
          updateSlotshipmentTs={updateSlotshipmentTs}
          averageWeight={averageWeight}
          maxNewSeats={maxNewSeats}
          disableSaveButton={setDisabledSave}
        /> }
        { [Tabs.truckRuns, Tabs.truckRunsRefused, Tabs.truckRunsRedirected].includes(currentTab) && <TruckRunsList
          bid={bid}
          onChange={init}
          onlyRefused={currentTab === Tabs.truckRunsRefused}
          onlyRedirected={currentTab === Tabs.truckRunsRedirected}
          viewTruckRunId={viewTruckRunId}
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          carrierList={carrierListFiltred}
          slotList={slotList}
        /> }
      </div>
    </div>
    <DeleteModal
      id={deleteId}
      onClose={() => setDeleteId(undefined)}
      onDelete={() => navigate(links.DISPATCHER_BIDS_PAGE)} />

    <BlockedManualFillModal
      id={blockedId}
      onClose={() => setBlockedId(undefined)}
      onBlocked={() => init().then(() => setBlockedId(undefined))}
    />

    <UnblockManualFillModal
      id={unblockedId}
      onClose={() => setUnblockedId(undefined)}
      onSuccess={() => {
        setUnblockedId(undefined)
        init()
      }}
    />

    <ExtensionBidModal
      bid={extensionBid}
      setBid={setExtensionBid}
      onClose={() => { setExtensionBid(undefined) }}
      onSuccess={() => {
        setExtensionBid(undefined)
        init()
      }}
    />
  </>)
}
