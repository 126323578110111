import './styles.sass'
import { Stack, Box, Button, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import DataTable, { Column } from 'ui/DataTable'
import { UserStatus } from 'api/UserStatus'
import { useNavigate, useParams } from 'react-router-dom'
import ActionsMenu from 'ui/ActionsMenu'
import { profileAllList, ProfileAll, ProfileAllFilterList, ProfileArchiveParams, profileAllArchive } from 'api/profile'
import { UserRole } from 'api/user'
import { Person, personName } from 'util/personName'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { useAuthContext } from 'AuthContext'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ArchiveIcon from '@mui/icons-material/Archive'
import EditIcon from '@mui/icons-material/Edit'
import formatPhone from 'util/formatPhone'
import { OrganizationAll } from 'api/organization'
import SquareButton from 'ui/SquareButton'
import useDisabled from 'common/Registry/Carrier/useDisabled'
import ChipUserStatus from 'common/ChipUserStatus'
import { Id } from 'api/Id'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import { isEnum } from 'util/isEnum'
import Tooltip from 'ui/Tooltip'
import { ReportProblem } from '@mui/icons-material'
import { checkCompletenessAccountingDocs } from 'ui/CarrierProfileCompletenessAlert'

const statusMap = new Map<UserStatus | '', string>()
  .set('', 'Все')
  .set(UserStatus.active, 'Подтвержден')
  .set(UserStatus.new, 'В процессе регистрации')
  .set(UserStatus.registered, 'На рассмотрении')
  .set(UserStatus.rejected, 'Отказано')
  .set(UserStatus.archive, 'В архиве')

export default function Carriers () {
  const [rows, setRows] = useState<(ProfileAll & { incompleteAccountingDocs?: boolean })[]>()
  const [searched, setSearched] = useState<(number|string)[]>()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const { currentInterface } = useAuthContext()
  const { disabled } = useDisabled(currentInterface)
  const [archiveData, setArchiveData] = useState<ProfileArchiveParams | null>()
  const { status } = useParams()
  const filterStatus = isEnum(UserStatus)(status) ? status : ''

  const loadList = useCallback(() => {
    const profileFilter: ProfileAllFilterList = { role: UserRole.carrier }

    if (disabled) {
      profileFilter.status = [UserStatus.registered, UserStatus.confirmed, UserStatus.active]
    } else if (filterStatus !== '') {
      profileFilter.status = filterStatus === UserStatus.registered ? [UserStatus.registered, UserStatus.confirmed] : [filterStatus]
    }

    profileAllList(profileFilter).then(result => {
      setRows(result.map(item => ({
        ...item,
        incompleteAccountingDocs: item.organization ? checkCompletenessAccountingDocs(item.organization, item) : undefined
      })))
    })
  }, [disabled, filterStatus])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadList() }, [filterStatus])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<ProfileArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof ProfileArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await profileAllArchive(archiveData)
    setArchiveData(undefined)
    loadList()
  }, [archiveData, loadList])

  if (rows === undefined) {
    return <></>
  }

  const columns: Column[] = []

  columns.push({
    id: 'organization',
    label: 'Перевозчик',
    format: (organization) => {
      const org = organization as OrganizationAll | undefined

      return <>{org?.shortName}</>
    }
  })
  columns.push({
    id: 'organization',
    label: 'ИНН',
    format: (organization) => {
      const org = organization as OrganizationAll | undefined

      return <>{org?.inn}</>
    }
  })
  columns.push({
    id: ['firstName', 'secondName', 'familyName'],
    label: 'ФИО представителя',
    format: (firstName, secondName, familyName) => <>{personName({ firstName, secondName, familyName } as Person)}</>
  })
  columns.push({
    id: 'email',
    label: 'Email'
  })
  columns.push({
    id: 'phone',
    label: 'Номер телефона',
    format: (phone) => <Box sx={{ whiteSpace: 'nowrap' }}>{ formatPhone(phone as string) }</Box>
  })

  if (disabled) {
    columns.push({
      id: 'id',
      label: '',
      align: 'right',
      format: value => <SquareButton
        onClick={() => navigate(`${links.REGISTRY_CARRIER_PAGE}/${value}`)}
        sx={{
          background: '#B2B2B2',
          color: '#fff',
          padding: 0,
          '&:hover': {
            background: '#B2B2B2',
            border: 'none',
            filter: 'brightness(96%)'
          }
        }}
      >
          <MoreHorizIcon sx={{ fontSize: '16px' }}/>
        </SquareButton>
    })
  } else {
    columns.push({
      id: ['status', 'truckRuns'],
      label: 'Статус',
      format: (status, truckRuns) => <Box sx={{ width: '14em' }}><ChipUserStatus status={status as UserStatus} truckRuns={truckRuns as Id[] | undefined}/></Box>
    })
    columns.push({
      id: ['id', 'status', 'truckRuns', 'incompleteAccountingDocs'],
      label: '',
      align: 'center',
      format: (id, status, truckRuns, incompleteAccountingDocs) => (<Stack direction='row' justifyContent='right' gap={1} alignContent='center' width='100%'>
        { !!incompleteAccountingDocs && status === UserStatus.active && <Tooltip title={<Typography>Перевозчик добавил не все сканы бухгалтерских документов!</Typography>} arrow placement='left' view>
          <ReportProblem sx={{ color: '#EE6A5F', fontSize: '32px' }}/>
        </Tooltip> }
        <ActionsMenu key={`${id}`} actions = {
        disabled || status === UserStatus.archive || (Array.isArray(truckRuns) && truckRuns.length > 0)
          ? [
              {
                caption: disabled || status === UserStatus.archive ? 'Посмотреть' : 'Редактировать',
                onClick: () => navigate(`${links.REGISTRY_CARRIER_PAGE}/${id}`),
                icon: status === UserStatus.archive ? <VisibilityIcon /> : <EditIcon />
              }
            ]
          : [
              {
                caption: 'Редактировать',
                onClick: () => navigate(`${links.REGISTRY_CARRIER_PAGE}/${id}`),
                icon: <EditIcon />
              },
              {
                caption: 'Архивировать',
                onClick: () => openArchiveModal(id as Id),
                icon: <ArchiveIcon />
              }
            ]
      } />
      </Stack>)
    })
  }

  return (
    <>
      <Modal
        title='Архивировать перевозчика'
        open={archiveData !== undefined}
        onClose={() => setArchiveData(undefined)}
        content={archiveData
          ? <Stack sx={{ minWidth: '500px' }} >
              <Box sx={{ mb: 2 }}>
                <DatePicker
                  label='Дата архивации'
                  value={archiveData.date}
                  onChange={updateArchiveDate('date')}
                  disabled
                />
              </Box>
              <Box>
                <TextField
                  rows={6}
                  label="Комментарий"
                  width='100%'
                  value={archiveData.comment}
                  onChange={updateArchiveString('comment')}
                  placeholder="Добавьте комментарий, например причину расторжения договора"
                />
              </Box>
            </Stack>
          : <></>
        }
        actions={ archiveData
          ? <>
            <Button color="secondary" variant="outlined" onClick={() => setArchiveData(undefined)}>Отменить</Button>
            <Button variant="contained" onClick={ () => { archive() } }>Архивировать</Button>
          </>
          : <></>
        }
      />
      <div className='registryCarriers'>
        <NavigatePanel
          search={{
            rows: rows === undefined ? [] : rows,
            fields: [
              'phone',
              'firstName',
              'secondName',
              'familyName',
              ['organization', 'shortName'],
              ['organization', 'inn']
            ],
            onChange: setSearched
          }}
          title='Перевозчики'
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.REGISTRY_CARRIERS_PAGE)
          }}
          tabs={disabled
            ? undefined
            : {
                items: [...statusMap].map(([value, label]) => ({ label, value })),
                value: filterStatus,
                onChange: value => navigate(links.REGISTRY_CARRIERS_PAGE + (value === '' ? '' : `/${value}`)),
                scrollable: true
              }
          }
        />
        <div className='registryCarriers__content'>
          <div className='registryCarriers__content_table'>
            <DataTable
              columns={columns}
              rows = { (start, end) => rows === undefined
                ? []
                : rows
                  .filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
              rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
            />
          </div>
        </div>
      </div>
    </>
  )
}
