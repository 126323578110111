import { Box, Checkbox, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'AuthContext'
import { useEffect, useMemo, useState } from 'react'
import { ComponentParamsInterface, Steps, ChangeStepStatus } from '..'
import './styles.sass'
import { Organization, TypeLegalPerson, TypeTaxation } from 'api/organization'
import { Passport } from 'api/passport'
import Upload from 'ui/Upload'
import { UploadParamsPassp } from 'common/Documents'
import {
  schemaUploadForCreateStProfile as schemaOrgSt,
  schemaUploadForCreateLtdProfile as schemaOrgLtd
} from 'validation/Organization'
import { schemaPassportUploadForCreateProfile as schemaPassport } from 'validation/Passport'
import useValidate from 'validation/validate'
import onlyFields from 'util/onlyFields'
import Modal from 'ui/Modal'
import Policy from 'pages/Policy'
import { Information } from 'api/information'
import download from 'util/download'
import { Profile } from 'api/profile'
import AntSwitch from 'ui/AntSwitch'

export default function CopiesData (props: {
  params: ComponentParamsInterface
  dataOrg: Partial<Organization>
  setDataOrg: React.Dispatch<React.SetStateAction<Partial<Organization>>>
  dataPassp: Partial<Passport>,
  setDataPassp: React.Dispatch<React.SetStateAction<Partial<Passport>>>
  info: Information
  profile: Partial<Profile>
  setProfile: React.Dispatch<React.SetStateAction<Partial<Profile>>>
}) {
  const { handleResponseFailure } = useAuthContext()

  const { dataOrg, setDataOrg, dataPassp, setDataPassp, info, profile, setProfile } = props
  const { currentStep, setCurrentStep, changeStep, setChangeStep, prevStep, nextStep } = props.params
  const [consent, setConsent] = useState<boolean>(false)
  const [loadReportEFS, setLoadReportEFS] = useState<boolean>(!!dataOrg?.scanReportEFS)
  // const [scanReportEFSError, setScanReportEFSError] = useState<string>()
  // const [accountingBalanceError, setAccountingBalanceError] = useState<string>()
  // const [scanInformationLetterError, setScanInformationLetterError] = useState<string>()
  // const [scanPatentError, setScanPatentError] = useState<string>()
  // const [scanInsuredPersonsError, setScanInsuredPersonsError] = useState<string>()
  // const [manningTableError, setManningTableError] = useState<string>()
  // const [scanConsentTaxpayerError, setScanConsentTaxpayerError] = useState<string>()
  // const [scanReceiptConsentTaxpayerError, setScanReceiptConsentTaxpayerError] = useState<string>()
  const [refuseConsentTaxpayer, setRefuseConsentTaxpayer] = useState<boolean>()
  const [scanLicenseError, setScanLicenseError] = useState<string>()
  const [scanPowerOfAttorneyError, setScanPowerOfAttorneyError] = useState<string>()
  // const [scanFinancialResultsError, setScanFinancialResultsError] = useState<string>()
  // const [scanTaxReturnOrFeeDeclarationError, setScanTaxReturnOrFeeDeclarationError] = useState<string>()

  const [openPolicy, setOpenPolicy] = useState(false)

  const isLtd = useMemo(() => dataOrg.typeLegalPerson === TypeLegalPerson.organization, [dataOrg.typeLegalPerson])
  const isVat = useMemo(() => dataOrg.typeTaxation === TypeTaxation.vat, [dataOrg.typeTaxation])

  const { check: checkOrgSt, errors: errorsOrgSt } = useValidate(schemaOrgSt)
  const { check: checkOrgLtd, errors: errorsOrgLtd } = useValidate(schemaOrgLtd)
  const { check: checkPas, errors: errorsPas } = useValidate(schemaPassport)

  useEffect(() => {
    if (currentStep !== Steps.editCopies) {
      return
    }

    if (changeStep === ChangeStepStatus.next) {
      setChangeStep(ChangeStepStatus.inactive)

      let cOrg = isLtd
        ? checkOrgLtd(
          onlyFields(
            dataOrg,
            'scanCertificate', 'scanCode',
            'scanInn', 'scanRegistration',
            'scanTaxReturnOrFeeDeclaration', 'manningTable',
            // 'calcPersonalIncomeTax',
            'accountingBalance',
            'scanInformationLetter',
            'scanPatent',
            'scanLicense', 'scanFinancialResults',
            'scanBankCard', 'scanRegulations', 'scanAdministrationProtocol',
            'scanConsentTaxpayer', 'scanRegistrationAddress',
            'scanQuestionnaire',
            'scanInsuredPersons', 'scanReportEFS'
          )
        )
        : checkOrgSt(
          onlyFields(
            dataOrg,
            'scanCertificate', 'scanCode',
            'scanInn', 'scanRegistration',
            'scanTaxReturnOrFeeDeclaration', 'manningTable',
            // 'calcPersonalIncomeTax',
            'accountingBalance',
            'scanInsuredPersons', 'scanReportEFS',
            'scanConsentTaxpayer', 'scanReceiptConsentTaxpayer',
            'scanRegistrationAddress',
            'scanInformationLetter', 'scanPatent', 'scanQuestionnaire',
            'scanLicense', 'scanFinancialResults'
          )
        )

      if (dataOrg.hasLicense && !dataOrg.scanLicense) {
        setScanLicenseError('Поле обязательно к заполнению')
        cOrg = false
      } else {
        setScanLicenseError(undefined)
      }

      if (dataOrg.hasPowerOfAttorney && !dataOrg.scanPowerOfAttorney) {
        setScanPowerOfAttorneyError('Поле обязательно к заполнению')
        cOrg = false
      } else {
        setScanPowerOfAttorneyError(undefined)
      }

      // if (dataOrg.hasAccounting && !dataOrg.accountingBalance) {
      //   setAccountingBalanceError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setAccountingBalanceError(undefined)
      // }

      // if (profile.hasHiredDrivers && loadReportEFS && !dataOrg.scanReportEFS) {
      //   setScanReportEFSError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanReportEFSError(undefined)
      // }

      // if (dataOrg.typeTaxation === TypeTaxation.patent && !dataOrg.scanPatent) {
      //   setScanPatentError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanPatentError(undefined)
      // }

      // if (!dataOrg.scanInformationLetter) {
      //   setScanInformationLetterError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanInformationLetterError(undefined)
      // }

      // if (profile.hasHiredDrivers && !dataOrg.scanInsuredPersons) {
      //   setScanInsuredPersonsError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanInsuredPersonsError(undefined)
      // }

      // if (profile.hasHiredDrivers && !dataOrg.manningTable) {
      //   setManningTableError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setManningTableError(undefined)
      // }

      // if ((isLtd || isVat || !refuseConsentTaxpayer) && !dataOrg.scanConsentTaxpayer) {
      //   setScanConsentTaxpayerError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanConsentTaxpayerError(undefined)
      // }

      // if (isVat && !dataOrg.scanReceiptConsentTaxpayer) {
      //   setScanReceiptConsentTaxpayerError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanReceiptConsentTaxpayerError(undefined)
      // }

      // if ((isLtd || isVat) && !dataOrg.scanFinancialResults) {
      //   setScanFinancialResultsError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanFinancialResultsError(undefined)
      // }

      // if (isVat && !dataOrg.scanTaxReturnOrFeeDeclaration) {
      //   setScanTaxReturnOrFeeDeclarationError('Поле обязательно к заполнению')
      //   cOrg = false
      // } else {
      //   setScanTaxReturnOrFeeDeclarationError(undefined)
      // }

      const cPass = checkPas(onlyFields(dataPassp, 'scan1', 'scan2'))

      if (!cOrg || !cPass) {
        console.error({ errorsOrgSt, errorsOrgLtd })
        return
      }

      if (consent) {
        setCurrentStep(nextStep)
      } else {
        handleResponseFailure('Необходимо ваше согласие на обработку персональных данных')
      }
    } else if (changeStep === ChangeStepStatus.prev && prevStep !== undefined) {
      setCurrentStep(prevStep)
      setChangeStep(ChangeStepStatus.inactive)
    }
  }, [
    currentStep, nextStep, prevStep,
    changeStep, dataOrg, dataPassp,
    checkOrgSt, checkOrgLtd, isLtd,
    checkPas, setChangeStep, setCurrentStep,
    consent, handleResponseFailure, loadReportEFS,
    profile.hasHiredDrivers, isVat, refuseConsentTaxpayer,
    errorsOrgSt, errorsOrgLtd
  ])

  const updateUploadOrg = (key: keyof Organization) => (filename: string | undefined) => setDataOrg({ ...dataOrg, [key]: filename || undefined })
  const updateUploadPassp = (key: keyof UploadParamsPassp) => (filename: string | undefined) => setDataPassp({ ...dataPassp, [key]: filename || undefined })
  const updateHasHiredDrivers = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setProfile(prev => ({ ...prev, hasHiredDrivers: checked }))
    const cleaner = checked
      ? { scanInformationLetter: undefined }
      : {
          scanInsuredPersons: undefined,
          scanReportEFS: undefined,
          manningTable: undefined
        }

    setDataOrg(prev => ({ ...prev, ...cleaner }))

    if (!checked) {
      setLoadReportEFS(false)
    }
  }

  return (
    <>
      <Typography fontWeight={700} fontSize='21px' mb='1.2em'>Приложите копии документов</Typography>
      <Box>
        <Typography fontWeight={600} fontSize='18px' mb='1em'>Основные документы</Typography>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <Upload
            name='scanInn'
            label='Свидетельство ИНН'
            value={dataOrg?.scanInn}
            onChange={updateUploadOrg('scanInn')}
            sx={{ width: '33.33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
          <Upload
            name='scanRegistration'
            label={isLtd ? 'Выписка из ЕГРЮЛ' : 'Выписка из ЕГРИП'}
            value={dataOrg?.scanRegistration}
            onChange={updateUploadOrg('scanRegistration')}
            sx={{ width: '33.33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
          <Upload
            name='scanCode'
            label='Коды ОКПО'
            value={dataOrg?.scanCode}
            onChange={updateUploadOrg('scanCode')}
            sx={{ width: '33.33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
        </Stack>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <Upload
            name='scan1'
            label='Паспорт (разворот с фото)'
            value={dataPassp?.scan1}
            onChange={updateUploadPassp('scan1')}
            sx={{ width: '33.33%' }}
            errors={errorsPas}
          />
          <Upload
            name='scan2'
            label='Паспорт (прописка)'
            value={dataPassp?.scan2}
            onChange={updateUploadPassp('scan2')}
            sx={{ width: '33.33%' }}
            errors={errorsPas}
          />
          <Upload
            name='scanCertificate'
            label={isLtd ? 'Свидетельство ОГРН' : 'Свидетельство ОГРНИП'}
            value={dataOrg?.scanCertificate}
            onChange={updateUploadOrg('scanCertificate')}
            sx={{ width: '33.33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
        </Stack>
        { dataOrg.typeTaxation === TypeTaxation.patent && <Stack direction='row' spacing={2} mb='0.5em'>
          <Upload
            name='scanPatent'
            label='Патент'
            value={dataOrg?.scanPatent}
            onChange={updateUploadOrg('scanPatent')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            // errorMessage={scanPatentError}
          />
        </Stack> }
      </Box>
      <Box>
        <Typography fontWeight={600} fontSize='18px' mb='1em'>Бухгалтерские документы</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} mb={2}>
          <Stack direction='row' alignItems='center' width='67%'>
            <Checkbox
                checked={dataOrg?.hasLicense ?? false}
                onChange={e => setDataOrg(prev => ({ ...prev, hasLicense: e.target.checked }))}
                sx={{ ml: 0, pl: 0 }}
            />
            <Typography>Лицензия (разрешение) на осуществление деятельности (предмет договора лицензируемый вид деятельности)</Typography>
          </Stack>
          <Upload
            name='scanLicense'
            value={dataOrg?.scanLicense}
            onChange={updateUploadOrg('scanLicense')}
            sx={{ width: '33%' }}
            disabled={!dataOrg?.hasLicense}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            errorMessage={scanLicenseError}
          />
        </Stack>
        { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Копия банковской карточки с образцами подписей</Typography>
          <Upload
            name='scanBankCard'
            value={dataOrg?.scanBankCard}
            onChange={updateUploadOrg('scanBankCard')}
            sx={{ width: '33%' }}
            errors={errorsOrgLtd}
          />
        </Stack> }
        { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Устав (все страницы), действующие изменения к Уставу</Typography>
          <Upload
            name='scanRegulations'
            value={dataOrg?.scanRegulations}
            onChange={updateUploadOrg('scanRegulations')}
            sx={{ width: '33%' }}
            errors={errorsOrgLtd}
          />
        </Stack> }
        { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >
              Протокол собрания участников (акционеров) или Решение единств. участника/акционера о назначении ЕИО/Передаче полномочий ЕИО управляющей компании.
              В случае передачи полномочий ЕИО Управляющей компании - Протокол собрания участников (акционеров) или Решение единств. участника/акционера Управляющей компании о назначении ЕИО УК
          </Typography>
          <Upload
            name='scanAdministrationProtocol'
            value={dataOrg?.scanAdministrationProtocol}
            onChange={updateUploadOrg('scanAdministrationProtocol')}
            sx={{ width: '33%' }}
            errors={errorsOrgLtd}
          />
        </Stack> }
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%'>Копии документов, подтверждающих место нахождения {isLtd ? 'юридического лица' : 'ИП' } по месту регистрации (договор аренды, свидетельство о праве собственности{isLtd ? '' : ', штамп регистрации по месту жительства'})</Typography>
          <Upload
            name='scanRegistrationAddress'
            value={dataOrg?.scanRegistrationAddress}
            onChange={updateUploadOrg('scanRegistrationAddress')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} mb={2}>
          <Stack direction='row' alignItems='center' width='67%'>
            {!isLtd && <Checkbox
                checked={dataOrg?.hasAccounting ?? false}
                onChange={e => setDataOrg(prev => ({ ...prev, hasAccounting: e.target.checked }))}
                sx={{ ml: 0, pl: 0 }}
            /> }
            <Typography >Бухгалтерский баланс за последний отчетный период с отметкой ИФНС или квитанцией о приеме ИФНС (в электр.виде)</Typography>
          </Stack>
          <Upload
            name='accountingBalance'
            value={dataOrg?.accountingBalance}
            onChange={updateUploadOrg('accountingBalance')}
            sx={{ width: '33%' }}
            disabled={!dataOrg?.hasAccounting}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            // errorMessage={accountingBalanceError}
          />
        </Stack>
        { (isLtd || isVat) && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Отчет о финансовых результатах за последний отчетный период с отметкой ИФНС или квитанцией о приеме ИФНС (в электр.виде)</Typography>
          <Upload
            name='scanFinancialResults'
            value={dataOrg?.scanFinancialResults}
            onChange={updateUploadOrg('scanFinancialResults')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            // errorMessage={scanFinancialResultsError}
          />
        </Stack> }
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Копия налоговой декларации за {isLtd ? 'предыдущий' : 'последний' } отчетный период (УСН, ЕНВД, ЕСХН) с отметками налоговой инспекции о принятии данной декларации{isVat ? ' либо Декларация по НДС за последний отчетный период и подтверждение ее получения ИФНС (квитанция о приеме налоговой декларации в электр.виде)' : ''}</Typography>
          <Upload
            name='scanTaxReturnOrFeeDeclaration'
            value={dataOrg?.scanTaxReturnOrFeeDeclaration}
            onChange={updateUploadOrg('scanTaxReturnOrFeeDeclaration')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            // errorMessage={scanTaxReturnOrFeeDeclarationError}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} mb={2}>
          <Stack direction='row' alignItems='center' width='67%'>
            <Checkbox
              checked={dataOrg?.hasPowerOfAttorney ?? false}
              onChange={e => setDataOrg(prev => ({ ...prev, hasPowerOfAttorney: e.target.checked }))}
              sx={{ ml: 0, pl: 0 }}
            />
            <Typography>Сотрудник компании действует по доверенности</Typography>
          </Stack>
          <Upload
            name='scanPowerOfAttorney'
            value={dataOrg?.scanPowerOfAttorney}
            onChange={updateUploadOrg('scanPowerOfAttorney')}
            sx={{ width: '33%' }}
            disabled={!dataOrg?.hasPowerOfAttorney}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            errorMessage={scanPowerOfAttorneyError}
          />
        </Stack>
        {/*
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Расчет сумм налога на доходы физических лиц, исчисленных и удержанных налоговым агентом (Форма 6-НДФЛ) за предыдущий отчетный период (при аттестации/заключении договора,+ не позже 10 календарных дней с даты истечение срока сдачи отчета по Форме 6-НДФЛ за отчетный период, в течение которого выполнялась перевозка</Typography>
          <Upload
            name='calcPersonalIncomeTax'
            value={dataOrg?.calcPersonalIncomeTax}
            onChange={updateUploadOrg('calcPersonalIncomeTax')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
        </Stack>
        */}
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Согласие на признание сведений, составляющих налоговую тайну, общедоступными по форме, утвержденной приказом ФНС России от 14.11.2022 N ЕД-7-19/1085@ «Об утверждении документов, предусмотренных подпунктом 1 пункта 1 и пунктом 2.3 статьи 102 Налогового кодекса Российской Федерации» (Код комплекта сведений 20013), или (при предоставлении Согласия до 13.12.2022 г) по форме, утвержденной Приказом ФНС России от 15.11.2016 № ММВ-7-17/615@, с соблюдением порядка заполнения, установленного Письмом ФНС России от 19.03.2020 г №СД-4-2/4748</Typography>
          <Stack width='33%'>
            { !isLtd && !isVat && <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px', mb: 2 }}>
              <AntSwitch
                checked={refuseConsentTaxpayer}
                onChange={({ target: { checked } }) => {
                  setRefuseConsentTaxpayer(() => checked)
                  checked && setDataOrg(({ scanConsentTaxpayer, ...data }) => data)
                }}
                inputProps={{ 'aria-label': 'ant design' }}
              />
              <Typography>Отказываюсь от предоставления</Typography>
            </Stack>}
            <Upload
              name='scanConsentTaxpayer'
              value={dataOrg?.scanConsentTaxpayer}
              onChange={updateUploadOrg('scanConsentTaxpayer')}
              errors={isLtd ? errorsOrgLtd : errorsOrgSt}
              // errorMessage={scanConsentTaxpayerError}
              disabled={refuseConsentTaxpayer}
            />
          </Stack>
        </Stack>
        { isVat && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Квитанция о приеме налоговым органом по телекоммуникационным каналам связи Согласия налогоплательщика (плательщика страховых взносов) на признание сведений, составляющих налоговую тайну, общедоступными</Typography>
          <Upload
            name='scanReceiptConsentTaxpayer'
            value={dataOrg?.scanReceiptConsentTaxpayer}
            onChange={updateUploadOrg('scanReceiptConsentTaxpayer')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            // errorMessage={scanReceiptConsentTaxpayerError}
          />
        </Stack> }
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%'>Анкета по требованиям Хартии АПК РФ {info.sampleQuestionnaire !== undefined && <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => download(info.sampleQuestionnaire as string)}>(скачать образец)</span>}</Typography>
          <Upload
            name='scanQuestionnaire'
            value={dataOrg?.scanQuestionnaire}
            onChange={updateUploadOrg('scanQuestionnaire')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >
            Информационное письмо о деятельности {(isLtd ? info.sampleInformationLetterOrg : info.sampleInformationLetter) !== undefined && <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => download((isLtd ? info.sampleInformationLetterOrg : info.sampleInformationLetter) as string)}>(скачать образец)</span>}
          </Typography>
          <Upload
            name='scanInformationLetter'
            value={dataOrg?.scanInformationLetter}
            onChange={updateUploadOrg('scanInformationLetter')}
            sx={{ width: '33%' }}
            errors={isLtd ? errorsOrgLtd : errorsOrgSt}
            // errorMessage={scanInformationLetterError}
          />
        </Stack>
        <Stack direction='row' alignItems='center' width='67%'>
          <Checkbox
            checked={profile.hasHiredDrivers}
            onChange={updateHasHiredDrivers}
            disabled={isLtd}
            sx={{ ml: 0, pl: 0 }}
          />
          <Typography >Предусмотрены наемные водители в штате</Typography>
        </Stack>
        { profile.hasHiredDrivers && <>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
              <Typography width='67%' >Сведения о застрахованных лицах Формы КНД 1151162 «Персонифицированные сведения о физических лицах», утвержденный Приказом ФНС России от 29.09.2022 N ЕД-7-11/878@ (в части сведений о водителях, направляемых для оказания услуги по Договору, с закрытыми на копии/скан-копии данными СНИЛС, ИНН и Сведениями о сумме выплат и иных вознаграждений, начисленных в пользу физического лица его Работодателем)</Typography>
              <Upload
                name='scanInsuredPersons'
                value={dataOrg?.scanInsuredPersons}
                onChange={updateUploadOrg('scanInsuredPersons')}
                sx={{ width: '33%' }}
                errors={isLtd ? errorsOrgLtd : errorsOrgSt}
                // errorMessage={scanInsuredPersonsError}
              />
            </Stack>
            <Box>
              <Stack direction='row' alignItems='center' width='67%'>
                <Checkbox
                  checked={loadReportEFS}
                  onChange={e => setLoadReportEFS(e.target.checked)}
                  sx={{ ml: 0, pl: 0 }}
                />
                <Typography >Данные о водителе в Форме КНД 1151162 отсутствуют</Typography>
              </Stack>
              { loadReportEFS && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                <Typography width='67%'>Удостоверенная уполномоченным лицом копия отчета ЕФС-1, подраздел 1.1 с титульным листом утвержденная Постановлением Правления ПФ РФ от 31.10.2022 N 245п (в части соответствующего физического лица) с отметкой о его принятии Фондом пенсионного и социального страхования РФ.)</Typography>
                <Upload
                  name='scanReportEFS'
                  value={dataOrg?.scanReportEFS}
                  onChange={updateUploadOrg('scanReportEFS')}
                  sx={{ width: '33%' }}
                  disabled={!loadReportEFS}
                  errors={isLtd ? errorsOrgLtd : errorsOrgSt}
                  // errorMessage={scanReportEFSError}
                />
              </Stack> }
            </Box>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
            <Typography width='67%' >
              Выписка из штатной расстановки {info.sampleStaffing !== undefined && <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => download(info.sampleStaffing as string)}>(скачать образец)</span>}
            </Typography>
            <Upload
              name='manningTable'
              value={dataOrg?.manningTable}
              onChange={updateUploadOrg('manningTable')}
              sx={{ width: '33%' }}
              errors={isLtd ? errorsOrgLtd : errorsOrgSt}
              // errorMessage={manningTableError}
            />
          </Stack>
          </> }
      </Box>
      <Box mt={4}>
        <Stack direction='row' alignItems='center'>
          <Checkbox checked={consent} onChange={(e) => setConsent(e.target.checked) } sx={{ ml: 0, pl: 0 }} />
          <Typography onClick={() => setOpenPolicy(true)} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Соглашение с условиями обработки персональных данных</Typography>
        </Stack>
      </Box>
      <Modal
        title=''
        maxWidth='lg'
        open={openPolicy}
        onClose={() => setOpenPolicy(false)}
        content={<Box><Policy height='100%' /></Box>}
        actions={<></>}
      />
    </>
  )
}
