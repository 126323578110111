import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import Modal from 'ui/Modal'
import {
  shippingTruckRunGet,
  ShippingTruckRunStatus as Status,
  ShippingTruckRunForCreate,
  shippingTruckRunCreate
} from 'api/shipping'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Select, { Params as SelectParams } from 'ui/Select'
import { Driver, driverAllList, DriverStatus } from 'api/driver'
import { Trailer, trailerAllList, TrailerStatus } from 'api/trailer'
import { Vehicle, vehicleAllList, VehicleStatus } from 'api/vehicle'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import TextField from 'ui/TextField'
import { ForwarderList, forwarderList as apiForwarderList } from 'api/forwarder'
import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
import DatePicker from 'ui/DatePicker'
import { toKilogram, toTon } from 'util/weight'
import SelectAddress from 'ui/SelectAddress'
import SelectOrganization from 'ui/SelectOrganization'
import valueMethod from 'util/valueMethod'
import { toKopeck, toRuble } from 'util/monetary'
import { Bid } from 'api/bid'
import useValidate from 'validation/validate'
import { baseSchema, autoFillSchema } from 'validation/truckRunCreate/TruckRunArrived'
import { UpdateParamsSlot } from 'pages/DispatcherBid/FreeSpaceEdit'
import { clear as clearTs, dayStart } from 'util/date'
import onlyFields from 'util/onlyFields'

export interface Params {
  open: boolean
  referenceId?: Id
  slotList: UpdateParamsSlot[]
  bid?: Bid
  carrierList: (SelectParams<Id>['options'][0] & { phone: string })[]
  onClose: () => void
  onSave: () => void
}

type Create = Partial<ShippingTruckRunForCreate>

const useDetails = (bid?: Bid) => {
  const details = { isRelay: false, autoFill: false, bidId: bid?.id, bidCarrier: bid?.carrier }

  if (bid?.carrier) {
    details.isRelay = true
  }

  if (bid?.autoFill) {
    details.autoFill = true
  }

  return details
}

export default function TruckRunCreateModal ({ open, bid, referenceId, slotList, carrierList, onClose, onSave }: Params) {
  const { bidId, isRelay, autoFill, bidCarrier } = useDetails(bid)
  const [data, setData] = useState<Create>({ status: Status.arrived })

  const [driverList, setDriverList] = useState<Driver[]>([])
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([])
  const [trailerList, setTrailerList] = useState<Trailer[]>([])
  const [forwarderList, setForwarderList] = useState<ForwarderList[]>([])
  const [conflicts, setConflicts] = useState<Awaited<ReturnType<typeof shippingTruckRunCreate>>['conflicts']>()

  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()

  const { check: checkBase, errors: errorsBase } = useValidate(baseSchema)
  const { check: checkAutoFill, errors: errorsAutoFill } = useValidate(autoFillSchema)
  const errors = autoFill ? errorsAutoFill : errorsBase
  const checkData = autoFill ? checkAutoFill : checkBase

  const { startTs } = useMemo(() => {
    const shipments = slotList.map(item => item.shipmentTs).filter(item => !!item)

    return {
      startTs: shipments.length > 0 ? Math.min(...shipments as number[]) : undefined
    }
  }, [slotList])

  const init = useCallback(async () => {
    if (!isId(bidId)) {
      return
    }

    if (!isId(referenceId)) {
      return
    }

    const result = await shippingTruckRunGet(referenceId)

    if (result === null) {
      return
    }

    const { owner, driver, vehicle, forwarder, trailer, consignee, unloadingAddress } = result

    setData(prev => ({
      ...prev,
      ...onlyFields(result, 'loadingTs', 'unloadingTs', 'billNumber', 'billTs', 'weight', 'finalWeight', 'costCorrection'),
      owner,
      driverId: driver.id,
      vehicleId: vehicle.id,
      forwarderId: forwarder?.id,
      trailerId: trailer?.id,
      consigneeId: consignee?.id,
      unloadingAddressId: unloadingAddress?.id
    }))
  }, [referenceId, bidId])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (!bidCarrier) {
      return
    }

    if (!open) {
      return
    }

    setData(prev => ({ ...prev, owner: bidCarrier.organization?.owner }))
  }, [bidCarrier, open])

  useEffect(() => {
    if (!data.owner) {
      return
    }

    const { owner } = data

    driverAllList({ owner, status: DriverStatus.active }).then(setDriverList)
    vehicleAllList({ owner, status: VehicleStatus.active }).then(setVehicleList)
    trailerAllList({ owner, status: TrailerStatus.active }).then(setTrailerList)
    apiForwarderList().then(setForwarderList)
  }, [data])

  const handleCancel = useCallback(() => {
    setData({ status: Status.arrived })
    setConflicts(undefined)
    onClose()
  }, [onClose])

  const save = async () => {
    if (!isId(bidId)) {
      return
    }

    if (!checkData(data)) {
      console.log(errors)
      return
    }

    const { success, conflicts } = await shippingTruckRunCreate({ bidId, ...data, referenceId })

    if (success) {
      onSave()
      handleResponseSuccess('Данные сохранены')
      handleCancel()
      return
    }

    handleResponseFailure('Данные не были сохранены')
    if (conflicts === undefined) {
      handleCancel()
      return
    }

    setConflicts(conflicts)
  }

  const onChangeSelect = (key: keyof Create) => {
    return (value: unknown) => {
      if (isId(value)) {
        setData(prev => ({ ...prev, [key]: value }))
      }
    }
  }

  const updateNumber = (key: keyof Create) => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setData(prev => ({ ...prev, [key]: value === '' ? undefined : toKilogram(Number(value)) }))
  }

  const updateId = (key: keyof Create) => {
    return (id?: Id) => setData({ ...data, [key]: id })
  }

  const updateString = (key: keyof Create) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e
      setData(prev => ({ ...prev, [key]: value }))
    }
  }

  return (<Modal
    maxWidth='lg'
    open={open || !!referenceId}
    onClose={handleCancel}
    title={`Добавить рейс для перевозки ${bid?.num}`}
    content={ conflicts === undefined
      ? <Box minWidth='40em' >
          <InfoCard>
            <Select
              name='owner'
              label='Перевозчик'
              placeholder='Выберите перевозчика'
              options={carrierList.map(({ phone, name }) => ({ value: phone, name }))}
              disableClearable={true}
              value={data.owner}
              onChange={(owner) => setData((data) => ({ ...data, owner }))}
              width='100%'
              disabled={isRelay}
              errors={errors}
            />
          </InfoCard>
          { data.owner && <>
          <InfoCard title='Основная информация'>
            <Stack gap={1}>
              <DatePicker
                name='billTs'
                label='Дата погрузки'
                value={data.loadingTs}
                onChange={loadingTs => setData(prev => ({ ...prev, loadingTs }))}
                width='100%'
                errors={errors}
                shouldDisableDate={startTs ? date => dayStart(clearTs(date.getTime())) < startTs : undefined}
              />
              <DatePicker
                name='billTs'
                label='Дата ТТН/ТрН'
                value={data.billTs}
                onChange={billTs => setData(prev => ({ ...prev, billTs }))}
                width='100%'
                errors={errors}
              />
              <TextField
                name='billNumber'
                label='Номер ТрН/ТТН'
                placeholder='Номер ТрН/ТТН'
                value={data.billNumber}
                onChange={updateString('billNumber')}
                errors={errors}
              />
              <Select
                name='driverId'
                label='Водитель'
                hint={autoFill ? 'Необязательное поле' : undefined}
                placeholder='Выберите водителя'
                options={ driverList.map(item => ({ value: item.id, name: personName(item) })) }
                value={data.driverId}
                onChange={onChangeSelect('driverId')}
                width='100%'
                errors={errors}
              />
              <Select
                name='vehicleId'
                label='ТС'
                placeholder='Выберите ТС'
                options={ vehicleList.map(item => ({ value: item.id, name: vehicleName(item) })) }
                value={data.vehicleId}
                onChange={onChangeSelect('vehicleId')}
                width='100%'
                errors={errors}
              />
              <Select
                name='trailerId'
                label='Прицеп'
                hint={autoFill ? 'Необязательное поле' : undefined}
                placeholder='Выберите прицеп'
                options={ trailerList.map(item => ({ value: item.id, name: trailerName(item) })) }
                value={data.trailerId}
                onChange={onChangeSelect('trailerId')}
                width='100%'
                errors={errors}
              />
              { !autoFill && <Select
                name='forwarderId'
                label='Экспедитор'
                placeholder='Выберите экспедитора'
                options={ forwarderList.map((item) => ({ value: item.id, name: personName(item) })) }
                value={data.forwarderId}
                onChange={onChangeSelect('forwarderId')}
                width='100%'
                errors={errors}
              /> }
              <TextField
                name='weight'
                label='Вес погрузки, тн'
                placeholder='Вес погрузки, тн'
                value={(data.weight !== undefined && data.weight > 0) ? toTon(data.weight) : undefined}
                typeNumber
                onChange={updateNumber('weight')}
                errors={errors}
              />
              <DatePicker
                name='unloadingTs'
                label='Дата разгрузки'
                value={data.unloadingTs}
                onChange={unloadingTs => setData(prev => ({ ...prev, unloadingTs }))}
                width='100%'
                errors={errors}
                shouldDisableDate={data.loadingTs ? date => dayStart(clearTs(date.getTime())) < (data.loadingTs as number) : undefined}
              />
              <TextField
                name='finalWeight'
                label='Вес разгрузки, тн'
                placeholder='Вес разгрузки, тн'
                value={(data.finalWeight !== undefined && data.finalWeight > 0) ? toTon(data.finalWeight) : undefined}
                typeNumber
                onChange={updateNumber('finalWeight')}
                errors={errors}
              />
            </Stack>
          </InfoCard>
          <InfoCard title='Дополнительные данные' >
            <Stack gap={1}>
              <SelectAddress
                name='unloadingAddressId'
                label='Название пункта разгрузки (переадресация)'
                placeholder='Выберите пункт зазгрузки'
                onChange={updateId('unloadingAddressId')}
                value={data.unloadingAddressId}
                itemName
                errors={errors}
              />
              <SelectOrganization
                name='consigneeId'
                label='Грузополучатель'
                placeholder='Выберете грузополучателя'
                value={data.consigneeId}
                onChange={updateId('consigneeId')}
                errors={errors}
              />
              <TextField
                name='costCorrection'
                label='Добавочная стоимость, руб.'
                value={valueMethod(data.costCorrection)(toRuble)}
                typeNumber
                onChange={({ target: { value } }) => setData((data) => ({ ...data, costCorrection: value === '' ? undefined : toKopeck(Number(value)) }))}
                errors={errors}
              />
            </Stack>
          </InfoCard>
          <InfoCard><>
            <TextField
              name='employeeComment'
              label='Комментарий диспетчера'
              placeholder='Укажите причину'
              rows={3}
              value={data.employeeComment}
              onChange={updateString('employeeComment')}
              errors={errors}
            />
          </></InfoCard>
          </>}
      </Box>
      : <Box minWidth='30em' >
        <Alert severity='error'>
          <Typography>Невозможно сохранить изменения!</Typography>
          { conflicts.bidNotFound && <Typography>Заявка не найдена</Typography> }
          { conflicts.vehicleNotFound && <Typography>Нельзя использовать выбранное ТС</Typography> }
          { conflicts.incorrectReferenceId && <Typography>Нельзя клонировать рейс</Typography> }
          { conflicts.billNumberExists && <Typography>Номер ТрН/ТТН {data.billNumber} используется в другом рейсе</Typography> }
          { conflicts.forwarderNotFound && <Typography>Выбранный экспедитор не найден</Typography> }
        </Alert>
      </Box>
    }
    actions={<Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
      <Button variant='outlined' onClick={handleCancel} >Отменить</Button>
      { conflicts === undefined && <Button variant='contained' onClick={save} >Cохранить</Button>}
    </Stack>}
  />)
}
